import React from 'react'
import GenericFormikInput from '../../../../GenericFormikInput'
import { Button, Zoom } from '@material-ui/core'
import deleteForeverSVG from "../../../../../Icons/trash-xmark.svg";
import cancelIconSVG from "../../../../../Icons/CancelIcon.svg";
import { convertBoolToYesNo } from '../../../../../_helper';
import { useModalDialog } from "../../../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../../../Modals/ConfirmModal";
import { SuccessModal } from "../../../../Modals/SuccessModal";
import { FailureModal } from "../../../../Modals/FailureModal";
import { CustomTooltip } from '../../../../Layout/Tooltip';

const CarrierTabsCarrierContactTableRow = ({ carrierContact, categories, types, mode, formikObject, index, onEdit, onSetEdit, carrierContacts, ...props }) => {
    const { touched, errors, values, setFieldValue, handleBlur, submitCount } = formikObject || {}
    const dialog = useModalDialog(0);
    
        function openModal(modalType, handleFunction, content) {
            switch (modalType) {
                case 0:
                    return ConfirmModal(dialog, handleFunction, content);
                case 1:
                    return SuccessModal(dialog, content);
                case 2:
                    return FailureModal(dialog, content);
                default:
                    break;
            }
        }
    
    const handleDeleteContactConfirm = async () => {
        openModal(0, async (e) => {
            setFieldValue(`CarrierContacts[${index}].isDelete`, 1);
        }, {
            title: 'Please Confirm',
            question: `Are you sure you want to delete this Contact?`,
        })
    }

    return (
        values?.CarrierContacts[index]?.isDelete !== 1 ?
        <div className='row table-row with-right-border'>
            <div className="col px-0">
                <div className="row">
                    <div className="col-2 table-cell first-cell d-flex flex-column word-break-all">
                        <GenericFormikInput
                            name={`CarrierContacts[${index}].FirstName`}
                            type={'text'}
                            placeholder='First Name'
                            isRequired={true}
                            isReadOnly={mode!=="add" && !onEdit}
                                readOnlyOptions={
                                {valueToShow: carrierContact?.FirstName,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                        />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                        <GenericFormikInput
                            name={`CarrierContacts[${index}].LastName`}
                            type={'text'}
                            placeholder='Last Name'
                            isRequired={true}
                            isReadOnly={mode!=="add" && !onEdit}
                                readOnlyOptions={
                                {valueToShow: carrierContact?.LastName,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                        />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                        <GenericFormikInput
                            name={`CarrierContacts[${index}].Email`}
                            type={'text'}
                            placeholder='Email'
                            isRequired={true}
                            isReadOnly={mode!=="add" && !onEdit}
                                readOnlyOptions={
                                {valueToShow: carrierContact?.Email,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                        />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                        <GenericFormikInput
                            name={`CarrierContacts[${index}].PhoneNumber`}
                            type={'text'}
                            placeholder='Phone Number'
                            isRequired={true}
                            isReadOnly={mode!=="add" && !onEdit}
                                readOnlyOptions={
                                {valueToShow: carrierContact?.PhoneNumber,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                        />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                        
                            <GenericFormikInput
                                name={`CarrierContacts[${index}].CarrierContactCategoryID`}
                                type={'optionList'}
                                isRequired={true}
                                isReadOnly={mode!=="add" && !onEdit}
                                readOnlyOptions={
                                {valueToShow: categories?.find(c => c.CarrierContactCategoryId === carrierContact?.CarrierContactCategoryID)?.CarrierContactCategoryName,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                                optionsListConfig={
                                    {
                                        optionsListMenu: categories || [],
                                        nameKey: 'CarrierContactCategoryName',
                                        valueKey: 'CarrierContactCategoryId',
                                        emptyOption: {
                                            isThereEmptyOption: true,
                                            emptyOptionDisabled: false,
                                            emptyOptionText: ''
                                        }
                                    }}
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                            />

                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                        
                            <GenericFormikInput
                                name={`CarrierContacts[${index}].CarrierContactTypeID`}
                                type={'optionList'}
                                isRequired={true}
                                isReadOnly={mode!=="add" && !onEdit}
                                readOnlyOptions={
                                {valueToShow: types?.find(c => c.CarrierContactTypeId === carrierContact?.CarrierContactTypeID)?.CarrierContactTypeName,
                                    extraCssClasses: "remove-textfield-div-style p-0" 
                                }
                            }
                                optionsListConfig={
                                    {
                                        optionsListMenu: types || [],
                                        nameKey: 'CarrierContactTypeName',
                                        valueKey: 'CarrierContactTypeId',
                                        emptyOption: {
                                            isThereEmptyOption: true,
                                            emptyOptionDisabled: false,
                                            emptyOptionText: ''
                                        }
                                    }}
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                            />
                    </div>
                    {/* Commented for now as per Dina's request to hide it */}
                    {/* <div className="col-1 table-cell d-flex flex-column word-break-all"> 
                            <GenericFormikInput 
                                label=""
                                name={`CarrierContacts[${index}].DisplayOnCRs`}
                                type="switch"
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                isDisabled={false}
                                isReadOnly={mode!=="add" && !onEdit}
                                readOnlyOptions={
                                {valueToShow: convertBoolToYesNo(carrierContact?.DisplayOnCRs) ,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                                switchConfig={{
                                    color: 'primary',
                                }}
                            />
                    </div> */}
                </div>
            </div>
            { (mode === "add" || onEdit) && ( 
    <div className="col-1 d-flex"  style={{ justifyContent: "center" }}>
        { (mode === "add" || (onEdit && !(carrierContact?.ContactID))) && (
            <CustomTooltip TransitionComponent={Zoom}
            title={onEdit && (carrierContacts?.filter(contact => contact?.isDelete !== 1).length <= 1 || carrierContacts?.length <= 1) ? "At least one carrier contact is required" : "Cancel"}
            >
                <Button
                    role="button"
                    className="icon-btn m-auto"
                    style={{ pointerEvents: "auto"  }}
                    onClick={(e) => {
                        e.stopPropagation();
                        const updatedCarrierContacts = [...values.CarrierContacts];
                        updatedCarrierContacts.splice(index, 1);
                        setFieldValue('CarrierContacts', updatedCarrierContacts);
                    }}
                    disabled={ onEdit && (carrierContacts?.filter(contact => contact?.isDelete !== 1).length <= 1 || carrierContacts?.length <= 1)}
                >
                    <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2">
                        <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                    </svg>
                </Button>
            </CustomTooltip>
        )}

        {(carrierContact?.ContactID && onEdit) && (
            <CustomTooltip TransitionComponent={Zoom} 
            title={(carrierContacts?.filter(contact => contact?.isDelete !== 1).length <= 1 || carrierContacts?.length <= 1) ? "At least one carrier contact is required" : "Delete"}
            >
                <span style={{alignContent: "center"}}>
                    <Button
                        className="icon-btn"
                        color="inherit"
                        onClick={handleDeleteContactConfirm}
                        disabled= {carrierContacts?.filter(contact => contact?.isDelete !== 1).length <= 1 || carrierContacts?.length <= 1}
                    >
                        <svg width="21" height="24" viewBox="0 0 21 24" style={{ cursor: "pointer" }}>
                            <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                        </svg>
                    </Button>
                </span>
            </CustomTooltip>
        )}
    </div>
)}


        </div>
        :
        <></>
    )
}

export default CarrierTabsCarrierContactTableRow
