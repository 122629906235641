import React, { useEffect } from 'react'
import { useAuthentication } from '../../../../Data/Authentication';
import { useModalDialog } from '../../../Layout/ModalDialogCustomized';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useParams } from 'react-router-dom';
import { Button, Zoom } from "@material-ui/core";
import GenericFormikInput from '../../../GenericFormikInput';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import editRequestIcon from "../../../../Icons/editIcon.svg";
import { useLoader } from '../../../Layout/Loader';
import * as APIs from '../../../../Data/APIs'

const validationSchema = Yup.object().shape({

    CarrierName: Yup.string().required('This Field is Required'),
    AllowforDualPlanYears: Yup.string().required('This Field is Required'),
    SeparateOEFiles: Yup.string().required('This Field is Required'),
    AreNewPlanYearEffectiveDatesRequired: Yup.string().required('This Field is Required'),

});

const CarrierTabsOE = ({ carrierDetails, getSingleCarrierHandler, onEdit, onSetEdit, ...props }) => {
    const [initialValues, setInitialValues] = React.useState({
        CarrierName: carrierDetails?.CarrierName,
        BlackoutPeriod: carrierDetails?.OE?.BlackoutPeriod == null ? 'Unknown' : "" + carrierDetails?.OE?.BlackoutPeriod,
        AllowforDualPlanYears: carrierDetails?.OE?.CarrierDualPlanYearID ? carrierDetails?.OE?.CarrierDualPlanYearID : '',
        SeparateOEFiles: carrierDetails?.OE?.CarrierSeparateOEFileID ? carrierDetails?.OE?.CarrierSeparateOEFileID : '',
        AreNewPlanYearEffectiveDatesRequired: carrierDetails?.OE?.CarrierNewPYEDRequiredID ? carrierDetails?.OE?.CarrierNewPYEDRequiredID : '',
        ResumeCurrentYearProductionFiles: carrierDetails?.OE?.ResumeCurrentYearProductionFiles == null ? 'Unknown' : "" + carrierDetails?.OE?.ResumeCurrentYearProductionFiles,
        CriticalOEFileDeadline: carrierDetails?.OE?.CriticalOEFileDeadline ? carrierDetails?.OE?.CriticalOEFileDeadline : '',
        OENotes: carrierDetails?.OE?.Notes ? carrierDetails?.OE?.Notes : ''
    })


    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
    const dialog = useModalDialog(0);
    const { carrierIDURL } = useParams();
    // const [onEdit, setOnEdit] = React.useState(false);
    const [expanded, setExpanded] = React.useState("OETab");
    const [dualPlanYearOptions, setDualPlanYearOptions] = React.useState([]);
    const [separateOEFilesOptions, setSeparateOEFilesOptions] = React.useState([]);
    const [newPlanYearRequiredOptions, setnewPlanYearRequiredOptions] = React.useState([]);
    const { openLoader, closeLoader } = useLoader()


    React.useEffect(() => {
        openLoader()
        Promise.all([
            APIs.getCarrierDualPlanOptions(),
            APIs.getCarrierSeparateOEFilesOptions(),
            APIs.getCarrierNewPYEDRequired()
        ])
            .then(([dualPlanResponse, separateOEResponse, newPlanResponse]) => {
                setDualPlanYearOptions(dualPlanResponse?.data);
                setSeparateOEFilesOptions(separateOEResponse?.data);
                setnewPlanYearRequiredOptions(newPlanResponse?.data);
            })
            .catch((error) => {
                console.log("Error fetching data:", error);
            }).finally(() => closeLoader())
    }, [])


    const handleSubmit = async (values, { setSubmitting }) => {
        openLoader();
        const editCarrierOEDetailsBody = {
            CarrierID: carrierDetails?.CarrierID,
            BlackoutPeriod: values?.BlackoutPeriod === "Unknown" ? null : values?.BlackoutPeriod,
            CarrierDualPlanYearId: values?.AllowforDualPlanYears,
            CarrierSeparateOEFileId: values?.SeparateOEFiles,
            CarrierNewPYEDRequiredId: values?.AreNewPlanYearEffectiveDatesRequired,
            CriticalOEFileDeadline: values?.CriticalOEFileDeadline,
            ResumeCurrentYearProductionFiles: values?.ResumeCurrentYearProductionFiles === "Unknown" ? null : values?.ResumeCurrentYearProductionFiles,
            Notes: values?.OENotes,

        }

        APIs.editCarrierOEDetails(editCarrierOEDetailsBody).then((r) => {
            getSingleCarrierHandler();
        });

    };

    const handleCancel = (resetForm) => {
        resetForm({ values: initialValues });
        onSetEdit(false);
    };

    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                {({ touched, errors, values, setFieldValue, handleBlur, submitCount, resetForm }) =>
                (
                    <Form className='d-flex flex-column'>
                        <Accordion defaultExpanded expanded={expanded === 'OETab'}>
                            <AccordionSummary
                                aria-controls="panel2-content"
                                id="panel-header-cr-config"
                            >
                                <span>OE Information</span>
                                {(userRoles?.includes("7") // Super Admin
                                    || userRoles?.includes("2")  //iSolved Super Admin 
                                ) && (

                                        <Button variant="contained" className="blue-outlined-btn mr-2 ml-auto" onClick={() => {
                                            if (onEdit) {
                                                handleCancel(resetForm);
                                            } else {
                                                onSetEdit(true);
                                            }
                                        }}>
                                            <svg width="20" height="24" viewBox="0 0 25 24">
                                                <use xlinkHref={`${editRequestIcon}#editRequest`}></use>
                                            </svg>
                                            <label style={{ cursor: "pointer" }} className="ml-2 mb-0">Edit Configuration</label>
                                        </Button>
                                    )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={12} sm={12} md={6}>

                                        <GenericFormikInput
                                            label={'Carrier Name'}
                                            name={'CarrierName'}
                                            type={'text'}
                                            isRequired={true}
                                            isReadOnly={true}
                                            readOnlyOptions={{
                                                valueToShow: carrierDetails?.CarrierName
                                            }
                                            }
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>


                                        <GenericFormikInput
                                            label={'Blackout Period'}
                                            name={'BlackoutPeriod'}
                                            type={'optionlist'}
                                            optionsListConfig={
                                                {
                                                    optionsListMenu: [{ optionNameKey: "Yes", optionValueKey: "1" }, { optionNameKey: "No", optionValueKey: "0" }, { optionNameKey: "Unknown", optionValueKey: "Unknown" }],
                                                    nameKey: 'optionNameKey',
                                                    valueKey: 'optionValueKey',

                                                }}
                                            isRequired={true}
                                            isReadOnly={!onEdit}
                                            readOnlyOptions={{
                                                valueToShow: carrierDetails?.OE?.BlackoutPeriod === 1 ? "Yes" : carrierDetails?.OE?.BlackoutPeriod === 0 ? "No" : "Unknown"
                                            }}

                                            moreInfo='Some carriers have a blackout period after open enrollment where EDI feeds are paused and changes for the current year won’t go through until the end of the blackout period'
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />


                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>

                                        <GenericFormikInput
                                            label={'Allow for Dual Plan Years'}
                                            name={'AllowforDualPlanYears'}
                                            moreInfo='Can prior year enrollments be sent once the new plan year enrollments have been sent?'
                                            type={'optionlist'}
                                            optionsListConfig={
                                                {
                                                    optionsListMenu: dualPlanYearOptions || [],
                                                    nameKey: 'CarrierDualPlanYearName',
                                                    valueKey: 'CarrierDualPlanYearId',

                                                }}
                                            isRequired={true}
                                            isReadOnly={!onEdit}
                                            readOnlyOptions={{
                                                valueToShow: carrierDetails?.OE?.CarrierDualPlanYearName
                                            }
                                            }
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>

                                        <GenericFormikInput
                                            label={'Separate OE Files'}
                                            name={'SeparateOEFiles'}
                                            moreInfo='Does the carrier need a separate OE file or prefer the OE elections are sent via the regular production file?'
                                            type={'optionlist'}
                                            optionsListConfig={
                                                {
                                                    optionsListMenu: separateOEFilesOptions || [],
                                                    nameKey: 'CarrierSeparateOEFileName',
                                                    valueKey: 'CarrierSeparateOEFileId',

                                                }}
                                            isRequired={true}
                                            isReadOnly={!onEdit}
                                            readOnlyOptions={{
                                                valueToShow: carrierDetails?.OE?.CarrierSeparateOEFileName
                                            }
                                            }
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>

                                        <GenericFormikInput
                                            label={'Are New Plan Year Effective Dates Required?'}
                                            name={'AreNewPlanYearEffectiveDatesRequired'}
                                            type={'optionlist'}
                                            optionsListConfig={
                                                {
                                                    optionsListMenu: newPlanYearRequiredOptions || [],
                                                    nameKey: 'CarrierNewPYEDRequiredName',
                                                    valueKey: 'CarrierNewPYEDRequiredId',

                                                }}
                                            moreInfo='Does the carrier expect to have the plan renewal date as the coverage date for all existing employees?'
                                            isRequired={true}
                                            isReadOnly={!onEdit}
                                            readOnlyOptions={{
                                                valueToShow: carrierDetails?.OE?.CarrierNewPYEDRequiredName
                                            }
                                            }
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>

                                        <GenericFormikInput
                                            label={'Resume Current Year Production Files'}
                                            name={'ResumeCurrentYearProductionFiles'}
                                            type={'optionlist'}
                                            optionsListConfig={
                                                {
                                                    optionsListMenu: [{ optionNameKey: "Yes", optionValueKey: "1" }, { optionNameKey: "No", optionValueKey: "0" }, { optionNameKey: "Unknown", optionValueKey: "Unknown" }],
                                                    nameKey: 'optionNameKey',
                                                    valueKey: 'optionValueKey',
                                                }}
                                            moreInfo='Does the carrier allow current plan year production files to resume after the OE file (upcoming plan year) is processed?'
                                            isRequired={true}
                                            isReadOnly={!onEdit}
                                            readOnlyOptions={{
                                                valueToShow: carrierDetails?.OE?.ResumeCurrentYearProductionFiles === 1 ? "Yes" : carrierDetails?.OE?.ResumeCurrentYearProductionFiles === 0 ? "No" : "Unknown"
                                            }}
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>

                                        <GenericFormikInput
                                            label={'Critical OE File Deadline'}
                                            name={'CriticalOEFileDeadline'}
                                            type={'textarea'}
                                            isRequired={false}
                                            isReadOnly={!onEdit}
                                            readOnlyOptions={{
                                                extraCssClasses: "max-height preformatted-text",
                                                valueToShow: carrierDetails?.OE?.CriticalOEFileDeadline
                                            }
                                            }
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>

                                        <GenericFormikInput
                                            label={'OE Notes'}
                                            name={'OENotes'}
                                            type={'textarea'}
                                            isRequired={false}
                                            isReadOnly={!onEdit}
                                            readOnlyOptions={{
                                                extraCssClasses: "max-height preformatted-text",
                                                valueToShow: carrierDetails?.OE?.Notes
                                            }
                                            }
                                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                                        />

                                    </Grid>


                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        {onEdit && (
                            <div className="w-100 text-right mt-5">
                                <Button type="button" variant="contained" className="blue-outlined-btn mr-2" onClick={() => handleCancel(resetForm)}>Cancel</Button>
                                <Button type="submit" variant="contained" className="blue-btn" >Submit</Button>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    )
}

export default CarrierTabsOE
