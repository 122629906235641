import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Grid } from '@mui/material';
import { useLoader } from '../../../Layout/Loader';
import { helperInfoIcon, convertBoolToYesNo } from '../../../../_helper';
import * as APIs from '../../../../Data/APIs';
import CarrierTabsTechnicalInfoGenericFTP from './CarrierTabsTechnicalInfoGenericFTP';
import { useAuthentication } from '../../../../Data/Authentication';
import GenericFormikInput from '../../../GenericFormikInput';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import editRequestIcon from "../../../../Icons/editIcon.svg";
import { Button } from "@material-ui/core";

const validationSchema = Yup.object().shape({
  CarrierName: Yup.string().required('This Field is Required'),
});

const CarrierTabsTechnicalInfo = ({ carrierDetails, getSingleCarrierHandler, onEdit, onSetEdit, ...props }) => {
  const { TechnicalInfo } = carrierDetails || {};
  const { openLoader, closeLoader } = useLoader();
  const [templateChangeAccepted, setTemplateChangeAccepted] = useState([]);
  const [spreadsheetProviders, setSpreadsheetProviders] = useState([]);
  const [carrierCoveragesOnSingleFile, setCarrierCoveragesOnSingleFile] = useState([]);
  const [carrierTerminateByOmission, setCarrierTerminateByOmission] = useState([]);
  const [carrierGenderOptions, setCarrierGenderOptions] = useState([]);
  const [carrierGenericFTPTypes, setCarrierGenericFTPTypes] = useState([]);
  const [carrierGenericFTPProtocolsTypes, setCarrierGenericFTPProtocolsTypes] = useState([]);
  const [carrierSSHKeys, setCarrierSSHKeys] = useState([]);
  const { authUser } = useAuthentication();
  const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
//   const [onEdit, setOnEdit] = React.useState(false);
  const [expanded, setExpanded] = React.useState("technicalInfoTab");

  const [initialValues, setInitialValues] = React.useState({
    CarrierName: carrierDetails?.CarrierName,
    CarrierAcceptChangeTemplateID: carrierDetails?.TechnicalInfo?.CarrierAcceptChangeTemplateID ? carrierDetails?.TechnicalInfo?.CarrierAcceptChangeTemplateID : '',
    CarrierCoveragesOnSingleFileID: carrierDetails?.TechnicalInfo?.CarrierCoveragesOnSingleFileID ? carrierDetails?.TechnicalInfo?.CarrierCoveragesOnSingleFileID : '',
    CarrierGenderOptionID: carrierDetails?.TechnicalInfo?.CarrierGenderOptionID ? carrierDetails?.TechnicalInfo?.CarrierGenderOptionID : '',
    CarrierProductionNotification: "" + carrierDetails?.TechnicalInfo?.CarrierProductionNotification,
    CarrierRequireTestScenarios: "" + carrierDetails?.TechnicalInfo?.CarrierRequireTestScenarios,
    CarrierResponseTime: carrierDetails?.TechnicalInfo?.CarrierResponseTime ? carrierDetails?.TechnicalInfo?.CarrierResponseTime : '',
    CarrierSpreadsheetProviderID: carrierDetails?.TechnicalInfo?.CarrierSpreadsheetProviderID ? carrierDetails?.TechnicalInfo?.CarrierSpreadsheetProviderID : '',
    CarrierTerminateByOmissionID: carrierDetails?.TechnicalInfo?.CarrierTerminateByOmissionID ? carrierDetails?.TechnicalInfo?.CarrierTerminateByOmissionID : '',
    Notes: carrierDetails?.TechnicalInfo?.Notes ? carrierDetails?.TechnicalInfo?.Notes : ''
  })

  useEffect(() => {
    openLoader();

    Promise.all([
      APIs.getCarrierTemplateChangeAccepted(),
      APIs.getCarrierSpreadsheetProvider(),
      APIs.getCarrierCoveragesOnSingleFile(),
      APIs.getCarrierTerminateByOmission(),
      APIs.getCarrierGenderOptions(),
      APIs.getCarrierGenericFTPTypes(),
      APIs.getCarrierGenericFTPProtocolTypes(),
      APIs.getCarrierSSHKeys(),
    ])
      .then(
        ([
          templateChangeAcceptedResponse,
          spreadsheetProvidersResponse,
          carrierCoveragesOnSingleFileResponse,
          carrierTerminateByOmissionResponse,
          carrierGenderOptionsResponse,
          carrierGenericFTPTypesResponse,
          carrierGenericFTPProtocolsTypesResponse,
          carrierSSHKeysResponse
        ]) => {
          setTemplateChangeAccepted(templateChangeAcceptedResponse?.data);
          setSpreadsheetProviders(spreadsheetProvidersResponse?.data);
          setCarrierCoveragesOnSingleFile(carrierCoveragesOnSingleFileResponse?.data);
          setCarrierTerminateByOmission(carrierTerminateByOmissionResponse?.data);
          setCarrierGenderOptions(carrierGenderOptionsResponse?.data);
          setCarrierGenericFTPTypes(carrierGenericFTPTypesResponse?.data);
          setCarrierGenericFTPProtocolsTypes(carrierGenericFTPProtocolsTypesResponse?.data);
          setCarrierSSHKeys(carrierSSHKeysResponse?.data);
        }
      )
      .catch((error) => {
        console.log('Error fetching data:', error);
      })
      .finally(() => closeLoader());
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    openLoader();
    const editCarrierTechnicalDetailsBody = {
      CarrierID: carrierDetails?.CarrierID,
      CarrierTemplateChangeAcceptedId: values?.CarrierAcceptChangeTemplateID ? +(values?.CarrierAcceptChangeTemplateID) : null,
      CarrierSpreadsheetProviderId: values?.CarrierSpreadsheetProviderID ? values?.CarrierSpreadsheetProviderID : null,
      CarrierCoveragesOnSingleFileId: values?.CarrierCoveragesOnSingleFileID ? values?.CarrierCoveragesOnSingleFileID : null,
      ProductionNotification: +(values?.CarrierProductionNotification) == null ? null : +(values?.CarrierProductionNotification),
      CarrierTerminateByOmissionId: values?.CarrierTerminateByOmissionID ? values?.CarrierTerminateByOmissionID : null,
      CarrierGenderOptionId: values?.CarrierGenderOptionID ? values?.CarrierGenderOptionID : null,
      Notes: values?.Notes,
      CarrierResponseTime: values?.CarrierResponseTime,
      RequiresTestScenarios: values?.CarrierRequireTestScenarios == null ? null : +(values?.CarrierRequireTestScenarios),

    }

    APIs.editCarrierTechnicalInfo(editCarrierTechnicalDetailsBody).then((r) => {
      getSingleCarrierHandler();
    });

  };

  const handleCancel = (resetForm) => {
    resetForm();
    onSetEdit(false);
  };

  return (
    <React.Fragment>
      {/* Technical Information */}
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ touched, errors, values, setFieldValue, handleBlur, submitCount, resetForm }) =>
        (
          <Form className='d-flex flex-column'>
            <Accordion defaultExpanded expanded={expanded === 'technicalInfoTab'}>
              <AccordionSummary
                aria-controls="panel2-content"
                id="panel-header-cr-config"
              >
                <span>Technical Information</span>
                {(userRoles?.includes("7") // Super Admin
                  || userRoles?.includes("2")  //iSolved Super Admin 
                ) && (

                    <Button variant="contained" className="blue-outlined-btn mr-2 ml-auto" onClick={() => {
                      if (onEdit) {
                        handleCancel(resetForm);
                      } else {
                        onSetEdit(true);
                      }
                    }} >
                      <svg width="20" height="24" viewBox="0 0 25 24">
                        <use xlinkHref={`${editRequestIcon}#editRequest`}></use>
                      </svg>
                      <label style={{ cursor: "pointer" }} className="ml-2 mb-0">Edit Configuration</label>
                    </Button>
                  )}
              </AccordionSummary>
              <AccordionDetails>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  {/* Carrier Name */}
                  <Grid item xs={12} sm={12} md={6}>
                    <GenericFormikInput
                      label={'Carrier Name'}
                      name={'CarrierName'}
                      type={'text'}
                      isRequired={true}
                      isReadOnly={true}
                      readOnlyOptions={{
                        valueToShow: carrierDetails?.CarrierName
                      }
                      }
                      formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                    />
                  </Grid>

                  {/* Will Carrier Accept the Change Spreadsheet or Template */}
                  <Grid item xs={12} sm={12} md={6}>
                    <GenericFormikInput
                      label={'Will Carrier Accept the Change Spreadsheet or Template'}
                      name={'CarrierAcceptChangeTemplateID'}
                      moreInfo='For clients who are below the carrier’s minimum group size requirement, can we change the spreadsheet or template?'
                      type={'optionlist'}
                      optionsListConfig={
                        {
                          optionsListMenu: templateChangeAccepted || [],
                          nameKey: 'CarrierTemplateChangeAcceptedName',
                          valueKey: 'CarrierTemplateChangeAcceptedId',

                        }}
                      isRequired={false}
                      isReadOnly={!onEdit}
                      readOnlyOptions={{
                        valueToShow: TechnicalInfo?.CarrierTemplateChangeAcceptedName
                      }
                      }
                      formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                    />
                  </Grid>

                  {/* Carrier Provided Spreadsheet or isolved Created Spreadsheet */}
                  <Grid item xs={12} sm={12} md={6}>
                    <GenericFormikInput
                      label={'Carrier Provided Spreadsheet or isolved Created Spreadsheet'}
                      name={'CarrierSpreadsheetProviderID'}
                      moreInfo='Does the carrier provide a spreadsheet or want isolved to create one? (for cases where change spreadsheets are applicable)'
                      type={'optionlist'}
                      optionsListConfig={
                        {
                          optionsListMenu: spreadsheetProviders || [],
                          nameKey: 'CarrierSpreadsheetProviderName',
                          valueKey: 'CarrierSpreadsheetProviderId',

                        }}
                      isRequired={false}
                      isReadOnly={!onEdit}
                      readOnlyOptions={{
                        valueToShow: TechnicalInfo?.CarrierSpreadsheetProviderName
                      }
                      }
                      formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                    />
                  </Grid>

                  {/* All Lines of Coverage on a Single File */}
                  <Grid item xs={12} sm={12} md={6}>
                    <GenericFormikInput
                      label={'All Lines of Coverage on a Single File'}
                      name={'CarrierCoveragesOnSingleFileID'}
                      moreInfo='Can all lines of coverage be on a single file feed?'
                      type={'optionlist'}
                      optionsListConfig={
                        {
                          optionsListMenu: carrierCoveragesOnSingleFile || [],
                          nameKey: 'CarrierCoveragesOnSingleFileName',
                          valueKey: 'CarrierCoveragesOnSingleFileId',

                        }}
                      isRequired={false}
                      isReadOnly={!onEdit}
                      readOnlyOptions={{
                        valueToShow: TechnicalInfo?.CarrierCoveragesOnSingleFileName
                      }
                      }
                      formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                    />
                  </Grid>

                  {/* Production Notification/Discrepancy Reporting */}
                  <Grid item xs={12} sm={12} md={6}>
                    <GenericFormikInput
                      label={'Production Notification/Discrepancy Reporting'}
                      name={'CarrierProductionNotification'}
                      type={'optionlist'}
                      optionsListConfig={
                        {
                          optionsListMenu: [{ optionNameKey: "Yes", optionValueKey: "1" }, { optionNameKey: "No", optionValueKey: "0" }],
                          nameKey: 'optionNameKey',
                          valueKey: 'optionValueKey',

                        }}
                      isRequired={false}
                      isReadOnly={!onEdit}
                      readOnlyOptions={{
                        valueToShow: convertBoolToYesNo(TechnicalInfo?.CarrierProductionNotification)
                      }}
                      formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                    />
                  </Grid>

                  {/* Term By Omission */}
                  <Grid item xs={12} sm={12} md={6}>
                    <GenericFormikInput
                      label={'Term By Omission'}
                      name={'CarrierTerminateByOmissionID'}
                      moreInfo='Does the carrier terminate an employee if they are omitted from the file feed?'
                      type={'optionlist'}
                      optionsListConfig={
                        {
                          optionsListMenu: carrierTerminateByOmission || [],
                          nameKey: 'CarrierTerminateByOmissionName',
                          valueKey: 'CarrierTerminateByOmissionId',

                        }}
                      isRequired={false}
                      isReadOnly={!onEdit}
                      readOnlyOptions={{
                        valueToShow: TechnicalInfo?.CarrierTerminateByOmissionName
                      }
                      }
                      formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                    />
                  </Grid>

                  {/* Gender Options */}
                  <Grid item xs={12} sm={12} md={6}>
                    <GenericFormikInput
                      label={'Gender Options'}
                      name={'CarrierGenderOptionID'}
                      type={'optionlist'}
                      optionsListConfig={
                        {
                          optionsListMenu: carrierGenderOptions || [],
                          nameKey: 'CarrierGenderOptionName',
                          valueKey: 'CarrierGenderOptionId',

                        }}
                      isRequired={false}
                      isReadOnly={!onEdit}
                      readOnlyOptions={{
                        valueToShow: TechnicalInfo?.CarrierGenderOptionName
                      }
                      }
                      formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                    />
                  </Grid>

                  {/* Requires Test Scenarios */}
                  <Grid item xs={12} sm={12} md={6}>
                    <GenericFormikInput
                      label={'Requires Test Scenarios'}
                      name={'CarrierRequireTestScenarios'}
                      type={'optionlist'}
                      optionsListConfig={
                        {
                          optionsListMenu: [{ optionNameKey: "Yes", optionValueKey: "1" }, { optionNameKey: "No", optionValueKey: "0" }],
                          nameKey: 'optionNameKey',
                          valueKey: 'optionValueKey',

                        }}
                      isRequired={false}
                      isReadOnly={!onEdit}
                      readOnlyOptions={{
                        valueToShow: convertBoolToYesNo(TechnicalInfo?.CarrierRequireTestScenarios)
                      }}
                      formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                    />
                  </Grid>

                  {/* Technical Notes */}
                  <Grid item xs={12} sm={12} md={6}>
                    <GenericFormikInput
                      label={'Technical Notes'}
                      name={'Notes'}
                      type={'textarea'}
                      isRequired={false}
                      isReadOnly={!onEdit}
                      readOnlyOptions={{
                        extraCssClasses: "max-height preformatted-text",
                        valueToShow: TechnicalInfo?.Notes
                      }
                      }
                      formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                    />
                  </Grid>

                  {/* Standard Carrier Response Time Per Test File */}
                  <Grid item xs={12} sm={12} md={6}>
                    <GenericFormikInput
                      label={'Standard Carrier Response Time Per Test File'}
                      name={'CarrierResponseTime'}
                      type={'textarea'}
                      isRequired={false}
                      isReadOnly={!onEdit}
                      readOnlyOptions={{
                        extraCssClasses: "max-height preformatted-text",
                        valueToShow: TechnicalInfo?.CarrierResponseTime
                      }
                      }
                      formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                    />
                  </Grid>
                </Grid >
              </AccordionDetails>
            </Accordion>

            {onEdit && (
              <div className="w-100 text-right mt-5">
                <Button type="button" variant="contained" className="blue-outlined-btn mr-2" onClick={() => handleCancel(resetForm)}>Cancel</Button>
                <Button type="submit" variant="contained" className="blue-btn" >Submit</Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <CarrierTabsTechnicalInfoGenericFTP
        carrierDetails={carrierDetails}
        carrierGenericFTPTypes={carrierGenericFTPTypes}
        carrierGenericFTPProtocolsTypes={carrierGenericFTPProtocolsTypes}
        carrierSSHKeys={carrierSSHKeys}
        getSingleCarrierHandler={getSingleCarrierHandler}
      />
    </React.Fragment>
  )
}

export default CarrierTabsTechnicalInfo;
