import React from 'react';
import * as APIs from "../../../../Data/APIs";
import { useAuthentication } from '../../../../Data/Authentication';
import { useModalDialog } from '../../../Layout/ModalDialogCustomized';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import EncryptionKeyRow from '../../EncryptionKeyRow';
import AddEncryptionKey from '../../AddEncryptionKey';
import { useParams } from 'react-router-dom';
import RelationshipContactsTable from './RelationshipContacts/RelationshipContactsTable';
import CarrierTabsCarrierContactTable from './CarrierTabsCarrierContact/CarrierTabsCarrierContactTable';
import plusIconSVG from '../../../../Icons/PlusIconWhite.svg';
import { helperInfoIcon, emailRegex } from '../../../../_helper';
import GenericFormikInput from '../../../GenericFormikInput';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import editRequestIcon from "../../../../Icons/editIcon.svg";
import { Button } from "@material-ui/core";
import { useLoader } from '../../../Layout/Loader';
import { AlertModal } from '../../../Modals/AlertModal';
import { debounce } from "lodash";
import { emptyCarrierContact, emptyRelationshipContact } from '../../CreateCarrier';

const validationSchema = Yup.object().shape({
  CarrierName: Yup.string().trim().required('This Field is Required'),
  CarrierRelationshipContacts: Yup.array() 
    .of(
      Yup.object().shape({
        FirstName: Yup.string().required('This Field is Required'),
        LastName: Yup.string().required('This Field is Required'),
        Email: Yup.string().matches(emailRegex, 'Invalid Email Address').required('This Field is Required'),
      })
    ),

  CarrierContacts: Yup.array()
    .of(
      Yup.object().shape({
        FirstName: Yup.string().required('This Field is Required'),
        LastName: Yup.string().required('This Field is Required'),
        Email: Yup.string().matches(emailRegex, 'Invalid Email Address').required('This Field is Required'),
        CarrierContactCategoryID: Yup.string().required('This Field is Required'),
        CarrierContactTypeID: Yup.string().required('This Field is Required'),
      })
    )
    .min(1, 'Please add at least one carrier contact'),
});


    const CarrierTabsGeneralInfo = ({ carrierDetails, getSingleCarrierHandler, onEdit, onSetEdit, ...props }) => {
        const { GeneralInfo } = carrierDetails || {}
        const [initialValues, setInitialValues] = React.useState({});
        const { authUser } = useAuthentication();
        const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
        const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
        const dialog = useModalDialog(0);
        const { carrierIDURL } = useParams();
        const { openLoader, closeLoader } = useLoader();
        // const [onEdit, setOnEdit] = React.useState(false);
        const [expanded, setExpanded] = React.useState("carrierGeneralInfoTab");
        const [duplicateType, setDuplicateType] = React.useState("");
        const [duplicateCarriersList, setDuplicateCarriersList] = React.useState([]);
        const [similarCarriersList, setSimilarCarriersList] = React.useState([]);
        const [continueWithWarning, setContinueWithWarning] = React.useState(false);

        
    const openAlertModal = () => {
        setContinueWithWarning(false);

        AlertModal(
        dialog,
        // on confirm handler
        () => {
            if (duplicateType == "warning") setContinueWithWarning(true)
        },
        // content
        {
            title: 'Alert!',
            info:
            <div>
                {duplicateType == "error" ?
                <>
                    This carrier name already exists, please make sure you are adding a new carrier
                    <div className="text-left fs-12">
                    {duplicateCarriersList.map((dc, dci) =>
                        <a className='warningLink' key={`duplicatedCarrier-${dc?.CarrierID}`} href={`/Carriers/ViewCarrier/${dc?.CarrierID}`} target="_blank" rel="noopener noreferrer">{dc?.CarrierName}</a>
                    )}
                    </div>
                </> :
                <>
                    This carrier name is similar to the following carrier(s), are you sure you want to save a new one?
                    <div className="text-left fs-12">
                    {similarCarriersList.map((sc, sci) =>
                        (<a className='warningLink' key={`similarCarrier-${sc?.CarrierID}`} href={`/Carriers/ViewCarrier/${sc?.CarrierID}`} target="_blank" rel="noopener noreferrer">{sc?.CarrierName}</a>))}
                    </div>
                </>
                }
            </div >
            ,
            question: ``,
        },
        duplicateType === "error" ?  { yes: "Ok", no: "Cancel" } : { yes: 'Yes', no: 'No' }
        )
    }

  // the core function of carrier name validation
    const validateCarrierNameCore = async (carrierNameValue) => {
      openLoader();
      setContinueWithWarning(false);
      APIs.checkCarrierNameSameOrSimilar({ CarrierName: carrierNameValue })
        .then((res) => {
          setDuplicateType(res?.duplicateType?.toLowerCase() || '');
          setDuplicateCarriersList(res?.duplicateCarriers || []);
          setSimilarCarriersList(res?.similarCarriers || []);
        })
        .catch((error) => { console.log('Error while checking is same or similar carrier name :', error) })
        .finally(() => { closeLoader() })
    };
  
    const debouncedAsyncValidationForCarrierName = debounce(validateCarrierNameCore , 2000);

    const addRelationshipContactHandler = (e, values, setFieldValue) => {
        e.stopPropagation();
        setFieldValue('CarrierRelationshipContacts', [
          ...values?.CarrierRelationshipContacts,
          JSON.parse(JSON.stringify(emptyRelationshipContact))
        ]);
      }
    
      const addCarrierContactHandler = (e, values, setFieldValue) => {
        e.stopPropagation();
        setFieldValue('CarrierContacts', [
          ...values?.CarrierContacts,
          JSON.parse(JSON.stringify(emptyCarrierContact))
        ]);
      }
  
    const reformatObjectToSend = (values)=>{
        const objectToSend = {
        CarrierID: values?.CarrierID,
        CarrierName: values.CarrierName,
        CarrierCode: values.CarrierNumber,
        CarrierFederalTaxID: values?.CarrierFederalTaxID,          
        CarrierGeneralNotes: values?.CarrierGeneralNotes,
        CarrierContactNotes: values?.CarrierContactNotes,
        CarrierRelationshipContacts: values?.CarrierRelationshipContacts?.map(contact => ({
            RelationshipContactID: contact.RelationshipContactID || null,
            RelationshipContactFirstName: contact.FirstName || null,
            RelationshipContactLastName: contact.LastName || null,
            RelationshipContactJobTitle: contact.JobTitle || null,
            RelationshipContactEmail: contact.Email || null,
            RelationshipContactPhoneNumber: contact.PhoneNumber || null,
            RelationshipContactNotes: contact.Notes || null,
            isDelete: contact?.isDelete || null
        })),
        CarrierContacts: values?.CarrierContacts?.map(contact => ({
            ContactID: contact.ContactID || null,
            ContactFirstName: contact.FirstName,
            ContactLastName: contact.LastName,
            ContactJobTitle: contact.JobTitle,
            ContactEmail: contact.Email,
            ContactPhoneNumber: contact.PhoneNumber,
            ContactCategoryID: contact.CarrierContactCategoryID,
            ContactTypeID: contact.CarrierContactTypeID,
            ContactDisplayOnCRs: contact.DisplayOnCRs,
            isDelete: contact?.isDelete || null
        }))
        }
    
        if (duplicateType === "warning"){ 
           objectToSend['ContinueWithWarning'] = continueWithWarning;
        }
    
        return objectToSend;
    
      }

    const handleSubmit = async (values, { setSubmitting }) => {
        if (duplicateType) {
              if (duplicateType === "error") return;
              if (duplicateType === "warning" && !continueWithWarning) {
                openAlertModal(true);
                return;
              }
            }
        
            openLoader();
            const carrierObject = reformatObjectToSend(values);
            APIs?.editCarrierGeneralInfoDetails(carrierObject).then((res) => {
              if (res){
                getSingleCarrierHandler();
              }else{
                setDuplicateType(res?.duplicateType?.toLowerCase() || '');
                setDuplicateCarriersList(res?.duplicateCarriers || []);
                setSimilarCarriersList(res?.similarCarriers || []);
              }
            })
            .catch(err => console.log('Editing Carrier General Info Error:', err))        
      };
    const handleCancel = (resetForm) => {
        resetForm();
        onSetEdit(false);
    };

        React.useEffect(()=>{
            setInitialValues({
                CarrierID: carrierDetails?.CarrierID || null,
                CarrierName: carrierDetails?.CarrierName || "",
                CarrierFederalTaxID: GeneralInfo?.CarrierFederalTaxID || "",
                CarrierRelationshipContacts: GeneralInfo?.CarrierRelationshipContacts || [],
                CarrierContacts: GeneralInfo?.CarrierContacts || [],
                CarrierContactNotes: GeneralInfo?.CarrierContactNotes || "",
                CarrierGeneralNotes: GeneralInfo?.CarrierGeneralNotes || "",
              })
    
        },[carrierDetails])

    return (
        <React.Fragment>
            {/* Carrier Information */}
            <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({ touched, errors, values, setFieldValue, handleBlur, submitCount, resetForm }) =>
            (
            <Form className='d-flex flex-column'>
            <Accordion defaultExpanded expanded = {expanded === 'carrierGeneralInfoTab'}>
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-config"
                >
                    <span>Carrier Information</span>
                    {( userRoles?.includes("7") // Super Admin
                        || userRoles?.includes("2")  //iSolved Super Admin
                    )  && (

                    <Button variant="contained" className="blue-outlined-btn mr-2 ml-auto" onClick={() => {
                        if (onEdit) {
                            handleCancel(resetForm);
                        } else {
                            onSetEdit(true);
                        }
                    }} >
                        <svg width="20" height="24" viewBox="0 0 25 24">
                            <use xlinkHref={`${editRequestIcon}#editRequest`}></use>
                        </svg>
                        <label style={{ cursor: "pointer" }} className="ml-2 mb-0">Edit Configuration</label>
                    </Button>
                    )}
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} sm={12} md={6} className='d-flex flex-column'>
                            <GenericFormikInput
                                label={'Carrier Name'}
                                name={'CarrierName'}
                                type={'text'}
                                isRequired={true}
                                isReadOnly={!onEdit || carrierDetails?.CarrierName == "Third Party"}
                                readOnlyOptions={{
                                    valueToShow: carrierDetails?.CarrierName}
                                }
                                extraOnChange={(inputValue)=>{
                                    debouncedAsyncValidationForCarrierName(inputValue)
                                  }}
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                              />
                              {duplicateType === "error" && onEdit && <div className="text-danger"> This carrier name is duplicate as the following <span style={{ color: '#007bff ', textDecoration: 'underline' }} role='button' onClick={openAlertModal}>carrier</span></div>}
                              {duplicateType === "warning" && !continueWithWarning && onEdit && <div className="text-alert-yellow">This carrier name is similar to the following <span style={{color:'#007bff ', textDecoration: 'underline'}} role='button' onClick={openAlertModal}>carrier(s)</span></div>}
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <GenericFormikInput
                                label={'Carrier Code'}
                                name={'CarrierNumber'}
                                type={'text'}
                                moreInfo='The Carrier Code is the Partner ID, which comes from isolved HCM'
                                isRequired={false}
                                isReadOnly={true}
                                readOnlyOptions={{
                                    valueToShow: GeneralInfo?.CarrierNumber}
                                }
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                              />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>

                            <GenericFormikInput
                                label={'Carrier Federal Tax'}
                                name={'CarrierFederalTaxID'}
                                type={'text'}
                                isRequired={false}
                                isReadOnly={!onEdit}
                                readOnlyOptions={{
                                    valueToShow: GeneralInfo?.CarrierFederalTaxID}
                                }
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                              />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            
            {/* Encryption Keys */}
            <Accordion className='mt-5' defaultExpanded>
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-config"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{ pointerEvents: 'none' }}
                >
                    <div className="d-flex">
                        <span>Encryption Keys</span>
                        <span style={{ pointerEvents: 'auto' }}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}>
                            {helperInfoIcon('For security purposes, the transmitted data is encrypted using certificates issued from a public trusted certificate authority')}
                        </span>
                    </div>
                    {(userActions?.includes("37") ||
                        userActions?.includes("20")) && (
                            <div className="add-record-btn ml-auto"
                                style={{ pointerEvents: 'auto' }}
                                onClick={(e) => { e.stopPropagation(); dialog.openDialog("Add Public Key", <AddEncryptionKey carrierID={carrierIDURL} />, []) }
                                }
                            >
                                <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                                    <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                                </svg>
                                ADD
                            </div>
                        )}
                </AccordionSummary>
                <AccordionDetails className='p-0-imp'>
                    <>
                        <div className="row text-center table-header">
                            <div className="col-3 table-cell first-cell">Key Name</div>
                            <div className="col-3 table-cell">Key ID</div>
                            <div className="col-3 table-cell">Fingerprint</div>
                            {(userActions?.includes("37") ||
                                userActions?.includes("20")) ? (
                                <>
                                    <div className="col-2 table-cell">Created Date</div>
                                    <div className="col-1 table-cell">Actions</div>
                                </>
                            ) : (
                                <div className="col-3 table-cell">Created Date</div>
                            )}

                        </div>

            {GeneralInfo?.CarrierEncryptionKeys?.length > 0 ? (
              GeneralInfo?.CarrierEncryptionKeys?.map((e, ei) => (
                <EncryptionKeyRow
                  key={`encryption-keys-${ei}`}
                  keyItem={e}
                  index={ei}
                  carrierDetails={carrierDetails}
                  carrierID={carrierIDURL}
                />
              ))
            ) : (
              <>
                <div className="no-data-alert">
                  <h6>No Encryption Keys Added!</h6>
                </div>
              </>
            )}
          </>
        </AccordionDetails>
      </Accordion>

            {/* Relationship Contacts */}
            <Accordion className='mt-5' defaultExpanded>
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-config"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{ pointerEvents: 'none' }}
                >
                    <span>Relationship Contacts</span>
                    {onEdit && (
                    <div className="add-record-btn ml-auto"
                    style={{ pointerEvents: 'auto' }}
                    onClick={(e) => { addRelationshipContactHandler(e, values, setFieldValue) }
                    }
                  >
                    <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                      <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                    </svg>
                    ADD
                  </div>
                    )}
                </AccordionSummary>
                <AccordionDetails className='p-0-imp' style={{maxHeight:"400px", overflowY:"auto"}}>
                    <RelationshipContactsTable carrierRelationshipContacts={values?.CarrierRelationshipContacts} 
                    mode="view"
                    onEdit={onEdit}
                    onSetEdit={onSetEdit}
                    formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount, resetForm }}
                    />
                </AccordionDetails>
            </Accordion>

            {/* Carrier Contacts */}
            <Accordion className='mt-5' defaultExpanded>
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-config"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{ pointerEvents: 'none' }}
                >
                    <span>Carrier Contacts</span>
                    {onEdit && (
                    <div className="add-record-btn ml-auto"
                    style={{ pointerEvents: 'auto' }}
                    onClick={(e) => { addCarrierContactHandler(e, values, setFieldValue) }
                    }
                  >
                    <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                      <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                    </svg>
                    ADD
                  </div>
                    )}
                </AccordionSummary>
                <AccordionDetails className='p-0-imp' style={{maxHeight:"400px", overflowY:"auto"}}>
                    <CarrierTabsCarrierContactTable carrierContacts={values?.CarrierContacts}
                     mode="view"
                     onEdit={onEdit}
                     onSetEdit={onSetEdit}
                     formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount, resetForm }}
                     />
                     {submitCount > 0 &&
                    errors['CarrierContacts'] &&
                    touched['CarrierContacts'] &&
                    typeof errors['CarrierContacts'] === 'string' && (
                      <div className="text-danger p-2 fs-12">{errors['CarrierContacts']}</div>
                    )}
                </AccordionDetails>
            </Accordion>

            {/* Notes */}
            <Accordion className='mt-5' defaultExpanded>
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-config"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{ pointerEvents: 'none' }}
                >
                    <span>Notes</span>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                        <Grid item xs={12} sm={12} md={6}>
                            <GenericFormikInput
                                label={'Carrier Contact Notes'}
                                name={'CarrierContactNotes'}
                                type={'textarea'}
                                isRequired={false}
                                isReadOnly={!onEdit}
                                readOnlyOptions={{
                                    extraCssClasses: "max-height preformatted-text",
                                    valueToShow: GeneralInfo?.CarrierContactNotes}
                                }
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                              />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <GenericFormikInput
                                label={'General Notes'}
                                name={'CarrierGeneralNotes'}
                                type={'textarea'}
                                isRequired={false}
                                isReadOnly={!onEdit}
                                readOnlyOptions={{
                                    extraCssClasses: "max-height preformatted-text",
                                    valueToShow: GeneralInfo?.CarrierGeneralNotes}
                                }
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                              />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {onEdit && (
            <div className="w-100 text-right mt-5">
                <Button type="button" variant="contained" className="blue-outlined-btn mr-2" onClick={() => handleCancel(resetForm)}>Cancel</Button>
                <Button type="submit" variant="contained" className="blue-btn" >Submit</Button>
              </div>
            )}
            </Form>
            )}
            </Formik>

        </React.Fragment>
    )
}


export default CarrierTabsGeneralInfo;
