import React from "react";
import { Button } from "@material-ui/core";
import WarningIcon from '@material-ui/icons/Warning';


export function AlertModal(dialog, onClickConfirm, content, btnNames = { yes: 'Yes', no: 'No' }) {
    dialog.openDialog(
        <div className="confirm modal-head red color">
            {content.title}
        </div>,
        <div className="confirm modal-body-content">
            <div style={{textAlign:"center"}}>
            <WarningIcon className="alert-icon" color="error" fontSize="large" />
                </div>
            
            <label className="black2-color mt-4"> {content.info}</label>
            <br />
            <label className="black2-color mt-4 mb-4"> {content.question}</label>
            {content.list && content.list}
        </div>,
        [
            { body: <Button className="blue-btn">{btnNames?.yes}</Button>, onClick: (e) => { dialog.closeDialog(); onClickConfirm(e) } },
            { body: <Button className="blue-outlined-btn">{btnNames?.no}</Button>, onClick: () => { dialog.closeDialog() } }
        ]
    )
}