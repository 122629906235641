import React from "react";
import { matchSorter } from 'match-sorter'
import { Chip, Paper, TextField, Select } from "@material-ui/core";
import TabsTitles from "../../Layout/TabsTitles";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import CarrierContacts from "./CarrierContacts";
import SelectCarriersAndPlans from "./SelectCarriersAndPlans";
import Button from "@material-ui/core/Button";
import * as APIs from "../../../Data/APIs";
import { useHistory } from "react-router-dom";
import BreadcrumbSteps from "../../Layout/BreadcrumbSteps";
import { useLoader } from "../../Layout/Loader";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FilesAttachments from "../../FilesAttachments/FilesAttachments";
import SelectSubgroups from "./SelectSubgroups";
import UserAssignement from "./UserAssignment";
import SelectCarrierPlans from "./SelectCarrierPlans";
import { FailureModal } from "../../Modals/FailureModal";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import dateTimeSVG from "../../../Images/dateTime.svg";
import Moment from 'moment';
import FormsAttachments from "../../FormsAttachments/FormsAttachments";
import CreateThirdParty from "./CreateThirdParty";
import { parseISO } from 'date-fns';
import SelectConnectivityType from "./SelectConnectivityType";
import SelectClientInformation from "./SelectClientInformation";
import { AlertModal } from "../../Modals/AlertModal";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SelectVerifiedCarriers from "./SelectVerifiedCarriers";
import SelectCarrierDetails from "./SelectCarrierDetails";
import ClientContactRow from "./ClientContactRow";
import CreateGroupRequestClientContacts from "./CreateGroupRequestClientContacts";
import { emailPattern } from "../ViewEditGroupRequest/ViewGroupRequestClientContactsRow";
import calendarIconSVG from "../../../Icons/fal-calendar-alt.svg";
import Grid from '@mui/material/Grid';

import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import TruncatedChip from "../../Layout/TruncatedChip";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

function CreateGroupRequest() {

    const dialog = useModalDialog(0);
    const routeHistory = useHistory();
    const { openLoader, closeLoader } = useLoader();

    const [, forceUpdate] = React.useReducer(x => !x, false);
    const [indexCallback, setIndexCallback] = React.useState(-1);

    const [requestSubmitted, setRequestSubmitted] = React.useState(false);
    const [requestSaved, setRequestSaved] = React.useState(false);
    const [groupsInstancesByURL, setGroupsInstancesByURL] = React.useState([]);
    const [groupsInstanceAutoValue, setGroupsInstanceAutoValue] = React.useState("");
    const [groupsInstanceName, setGroupsInstanceName] = React.useState("");
    const [groupsInstanceID, setGroupsInstanceID] = React.useState("");

    const [isPEOClicked, setIsPEOClicked] = React.useState(false);
    const [subTypeList, setSubTypeList] = React.useState([]);
    const [formsSubTypeList, setFormsSubTypeList] = React.useState([]);

    const [expanded, setExpanded] = React.useState(true);
    const [groups, setGroups] = React.useState([]);

    const [groupsAndOEContactsList, setGroupsAndOEContactsList] = React.useState([]);
    const [groupContact, setGroupContact] = React.useState("");
    const [OEContact, setOEContact] = React.useState("");
    const [migration, setMigration] = React.useState("");

    const [groupSize, setGroupSize] = React.useState();
    const [groupEffDate, setGroupEffDate] = React.useState(null);

    const [migrationStartDate, setMigrationStartDate] = React.useState(null);
    const [plannedFirstProductionDate, setPlannedFirstProductionDate] = React.useState(null);
    const [migrationPhase, setMigrationPhase] = React.useState("");
    const [migrationPhasesList, setMigrationPhasesList] = React.useState([]);
    const [clientContacts, setClientContacts] = React.useState([]);
    const [clientContactsNotes, setClientContactsNotes] = React.useState("");

    const [selectedGroup, setSelectedGroup] = React.useState("");

    const [selectedGroupDetails, setSelectedGroupDetails] = React.useState("");
    const [selectedGroupCarriers, setSelectedGroupCarriers] = React.useState([]);
    const [internalCarriers, setInternalCarriers] = React.useState([]);
    const [internalCarrier, setInternalCarrier] = React.useState("");
    const [internalCarrierName, setInternalCarrierName] = React.useState("");

    const [subgroups, setSubgroups] = React.useState([]);
    const [selectedSubgroups, setSelectedSubgroups] = React.useState([]);
    const [subgroupSearchValue, setSubgroupSearchValue] = React.useState("");

    const [carrierSearchValue, setCarrierSearchValue] = React.useState("");
    const [selectedCarriers, setSelectedCarriers] = React.useState([]);
    const [selectedThirdParties, setSelectedThirdParties] = React.useState([]);
    const [selectedOtherCarriers, setSelectedOtherCarriers] = React.useState([]);
    const [selectedOtherCarriersChecked, setSelectedOtherCarriersChecked] = React.useState(false);
    const [selectedPlans, setSelectedPlans] = React.useState([]);

    const [otherPlanChecked, setOtherPlanChecked] = React.useState(false);

    const [carrierNotListedChecked, setCarrierNotListedChecked] = React.useState(false);

    // Validations
    const [selectGroupInstanceValidationForm, setSelectGroupInstanceValidationForm] = React.useState("");
    const [selectGroupValidationForm, setSelectGroupValidationForm] = React.useState("");
    const [selectClientContactValidationForm, setSelectClientContactValidationForm] = React.useState("");
    const [selectClientEffectiveDateValidationForm, setSelectClientEffectiveDateValidationForm] = React.useState("");
    const [selectCarrierValidationForm, setSelectCarrierValidationForm] = React.useState("");
    const [selectPlanValidationForm, setSelectPlanValidationForm] = React.useState("");
    const [selectCRTypeValidationForm, setSelectCRTypeValidationForm] = React.useState("");
    const [selectCRHREmailValidationForm, setSelectCRHREmailValidationForm] = React.useState("");
    const [attachFormsValidationForm, setAttachFormsValidationForm] = React.useState("");
    const [selectSubgroupValidationForm, setSelectSubgroupValidationForm] = React.useState("");
    const [contactValidationCallback, setContactValidationCallback] = React.useState("");
    const [matchCarriersValidationForm, setMatchCarriersValidationForm] = React.useState("");
    const [carrierCodeValidationForm, setCarrierCodeValidationForm] = React.useState("");
    const [carrierNameValidationForm, setCarrierNameValidationForm] = React.useState("");
    const [selectMigrationValidationForm, setSelectMigrationValidationForm] = React.useState("");
    const [inputsSavedValidation, setInputsSavedValidation] = React.useState(false);
    // const [selectFormMainTypesValidationForm, setSelectFormMainTypesValidationForm] = React.useState("");
    // const [selectFormSubTypesValidationForm, setSelectFormSubTypesValidationForm] = React.useState("");

    const [selectMigrationStarDateValidationForm, setSelectMigrationStarDateValidationForm] = React.useState("");
    const [selectPlannedFirstProductionDateValidationForm, setSelectPlannedFirstProductionDateValidationForm] = React.useState("");
    const [selectMigrationPhaseValidationForm, setSelectMigrationPhaseValidationForm] = React.useState("");
    const [thirdPartyNameValidationForm, setThirdPartyNameValidationForm] = React.useState("");
    const [thirdPartyPlansValidationForm, setThirdPartyPlansValidationForm] = React.useState("");

    const [thirdPartyContactsValidationForm, setThirdPartyContactsValidationForm] = React.useState("");
    const [thirdPartyContactsFirstNameValidationForm, setThirdPartyContactsFirstNameValidationForm] = React.useState("");
    const [thirdPartyContactsLastNameValidationForm, setThirdPartyContactsLastNameValidationForm] = React.useState("");
    const [thirdPartyContactsEmailValidationForm, setThirdPartyContactsEmailValidationForm] = React.useState("");
    const [thirdPartyContactsTypeValidationForm, setThirdPartyContactsTypeValidationForm] = React.useState("");

    const [thirdPartyEffectiveDateValidationForm, setThirdPartyEffectiveDateValidationForm] = React.useState("");
    const [selectThirdPartyTypeValidationForm, setSelectThirdPartyTypeValidationForm] = React.useState("");
    const [thirdPartyAttachFormsValidationForm, setThirdPartyAttachFormsValidationForm] = React.useState("");

    const [selectCRContactValidationForm, setSelectCRContactValidationForm] = React.useState("");
    const [selectCRContactFirstNameValidationForm, setSelectCRContactFirstNameValidationForm] = React.useState("");
    const [selectCRContactLastNameValidationForm, setSelectCRContactLastNameValidationForm] = React.useState("");
    const [selectCRContactEmailValidationForm, setSelectCRContactEmailValidationForm] = React.useState("");
    const [selectCRContactTypeValidationForm, setSelectCRContactTypeValidationForm] = React.useState("");


    const [tabValueCallback, setTabValueCallback] = React.useState(0);


    //PEOs

    const [PEOsList, setPEOsList] = React.useState([]);

    const carrierContactTypes = ["Test", "Production/Data Issues"];

    const checkAllThirdPartiesContactsValidation = () => {
        return thirdPartyContactsValidationForm ||
            thirdPartyContactsFirstNameValidationForm ||
            thirdPartyContactsLastNameValidationForm ||
            thirdPartyContactsEmailValidationForm ||
            thirdPartyContactsTypeValidationForm
    }

    React.useEffect(() => {
        if (!groupsInstanceName) setSelectGroupInstanceValidationForm("Group instance is missing, please select an instance URL from the list"); else setSelectGroupInstanceValidationForm("");
    }, [groupsInstanceName, selectGroupInstanceValidationForm])

    React.useEffect(() => {
        if (!selectedGroup) setSelectGroupValidationForm("Group is missing, please select a group from the list"); else setSelectGroupValidationForm("");
    }, [selectedGroup, selectGroupValidationForm])

    React.useEffect(() => {
        if (!groupContact) setSelectClientContactValidationForm(!isPEOClicked ? `iSolved contact is missing, please select a iSolved contact from the list` : `PEO Contact is missing, please select a PEO contact from the list`); else setSelectClientContactValidationForm("");
    }, [groupContact, selectClientContactValidationForm, isPEOClicked])

    React.useEffect(() => {
        if (!isPEOClicked) setPEOsList([]);
        else {
            APIs.getAllPEOs(groupsInstanceID).then(r => {
                setPEOsList(r?.data[0]);
            })
        }
    }, [groupsInstanceID, isPEOClicked])

    //Get EDI Sub Types (on Page start)
    React.useEffect(() => {
        APIs.getConnectivityRequestEDISubTypes().then((r) => {
            setSubTypeList(r?.data);
        });
    }, [])

    //Get Forms Sub Types (on Page start)
    React.useEffect(() => {
        APIs.getConnectivityRequestFormsSubTypes().then((r) => {
            setFormsSubTypeList(r?.data);
        });
    }, [])

    React.useEffect(() => {
        if (PEOsList === undefined) {
            FailureModal(dialog, {
                title: 'Failure!',
                body: 'This instance has no PEOs, please try with another instance!'
            });
            setIsPEOClicked(false);
        }
    }, [groupsInstanceID, isPEOClicked, PEOsList])

    React.useEffect(() => {
        if (selectedCarriers.length <= 0 && selectedThirdParties?.length <= 0 && (selectedOtherCarriers?.selectedOtherCarriersFullArray?.length <= 0 || (!selectedOtherCarriers?.selectedOtherCarriersFullArray && selectedOtherCarriers?.length <= 0))) setSelectCarrierValidationForm("Please select at least one carrier"); else setSelectCarrierValidationForm("");
    }, [selectedCarriers, selectedThirdParties, selectedOtherCarriers, selectCarrierValidationForm])

    React.useEffect(() => {
        if (selectedCarriers.filter(s => s.InternalCarrierID === null && (!s.CarrierInternalName || s.CarrierInternalName === "")).length > 0)
            setMatchCarriersValidationForm("Please match all selected Carriers"); else setMatchCarriersValidationForm("");
    }, [JSON.stringify(selectedCarriers), matchCarriersValidationForm])

    React.useEffect(() => {
        if (isPEOClicked && ((selectedCarriers && selectedCarriers?.filter(s => ((!s.Code || s.Code === ""))).length > 0) || (selectedOtherCarriers?.selectedOtherCarriersFullArray && selectedOtherCarriers?.selectedOtherCarriersFullArray?.filter(s => ((!s.Code || s.Code === ""))).length > 0)))
            setCarrierCodeValidationForm("Please add carrier code for all selected Carriers"); else setCarrierCodeValidationForm("");
    }, [JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), carrierCodeValidationForm])

    React.useEffect(() => {
        if (isPEOClicked && selectedOtherCarriers?.selectedOtherCarriersFullArray && selectedOtherCarriers?.selectedOtherCarriersFullArray?.filter(s => ((!s.Name || s.Name === ""))).length > 0)
            setCarrierNameValidationForm("Please add carrier name for all selected Carriers"); else setCarrierNameValidationForm("");
    }, [JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), carrierNameValidationForm])

    React.useEffect(() => {
        if ((selectedCarriers && selectedCarriers?.filter(s => ((!s.SelectedPlans || s.SelectedPlans.length <= 0) && (!s.OtherPlanTypes || s.OtherPlanTypes === "")) || (s.SelectedPlans.filter(s => s !== "Other").length <= 0 && (!s.OtherPlanTypes || s.OtherPlanTypes === ""))).length > 0) ||
            (selectedOtherCarriers?.selectedOtherCarriersFullArray && selectedOtherCarriers?.selectedOtherCarriersFullArray?.filter(s => ((!s.SelectedPlans || s.SelectedPlans.length <= 0) && (!s.OtherPlanTypes || s.OtherPlanTypes === "")) || (s.SelectedPlans.filter(s => s !== "Other").length <= 0 && (!s.OtherPlanTypes || s.OtherPlanTypes === ""))).length > 0)) {
            setSelectPlanValidationForm("Please select at least one plan for each Carrier selected");
        } else {
            setSelectPlanValidationForm("");
        }
    }, [JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), selectPlanValidationForm])

    React.useEffect(() => {

        let newselectedHREmailValidation = []
        let newselectedHREmailFormatValidation = []

        selectedCarriers?.map(c => {
            var emails = c.HREmail?.replace(/\s/g, '').split(",");
            var valid = true;

            for (var i = 0; i < emails?.length; i++) {
                if (emails[i] == "" || !emailPattern.test(emails[i])) {
                    valid = false;
                }
            }

            if (c.SelectedCRTypes?.includes("Forms") && c.HREmail?.split(" ").join("").length == 0) {
                newselectedHREmailValidation.push(c)
            }
            else if (c.SelectedCRTypes?.includes("Forms") && !valid) {
                newselectedHREmailFormatValidation.push(c)
            }

        });
        selectedOtherCarriers?.selectedOtherCarriersFullArray?.map(c => {
            var emails = c.HREmail?.replace(/\s/g, '').split(",");
            var valid = true;

            for (var i = 0; i < emails?.length; i++) {
                if (emails[i] == "" || !emailPattern.test(emails[i])) {
                    valid = false;
                }
            }

            if (c.SelectedCRTypes?.includes("Forms") && c.HREmail?.split(" ").join("").length == 0) {
                newselectedHREmailValidation.push(c)
            }
            else if (c.SelectedCRTypes?.includes("Forms") && !valid) {
                newselectedHREmailFormatValidation.push(c)
            }
        });


        if (newselectedHREmailValidation.length > 0) {
            setSelectCRHREmailValidationForm("Please add at least Email for each HR Email at each Carrier selected");
        }
        else if (newselectedHREmailFormatValidation.length > 0) {
            setSelectCRHREmailValidationForm("Please add valid Email for each HR Email at each Carrier selected");
        }
        else setSelectCRHREmailValidationForm("");
    }, [tabValueCallback, JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), thirdPartyContactsEmailValidationForm])


    // =================================================[Client Contact Validations]
    React.useEffect(() => {
        if ((selectedCarriers?.length > 0 && selectedCarriers && selectedCarriers?.filter(c => (c.Contacts ?? []).length === 0).length > 0) ||
            (selectedOtherCarriers?.selectedOtherCarriersFullArray && selectedOtherCarriers?.selectedOtherCarriersFullArray?.filter(c => (c.Contacts ?? []).length === 0).length > 0))
            setSelectCRContactValidationForm("Please add at least one Carrier contact for each Carrier selected");
        else setSelectCRContactValidationForm("");
    }, [tabValueCallback, JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), selectCRContactValidationForm])

    // Client Contact First Name Vaildation
    React.useEffect(() => {
        let newselectedCarriersContactsWithFirstNameValidation = []
        selectedCarriers?.map(c => {
            return c.Contacts?.map(contact => {
                if (contact.ConnectivityRequestContactFirstName.split(" ").join("").length == 0) {
                    newselectedCarriersContactsWithFirstNameValidation.push(contact)
                }
            })
        });
        selectedOtherCarriers?.selectedOtherCarriersFullArray?.map(c => {
            return c.Contacts?.map(contact => {
                if (contact.ConnectivityRequestContactFirstName.split(" ").join("").length == 0) {
                    newselectedCarriersContactsWithFirstNameValidation.push(contact)
                }
            })
        });

        if (newselectedCarriersContactsWithFirstNameValidation.length > 0) {
            setSelectCRContactFirstNameValidationForm("Please add at least First Name for each contact at each Carrier");
        }
        else setSelectCRContactFirstNameValidationForm("");
    }, [tabValueCallback, JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), , thirdPartyContactsFirstNameValidationForm])

    // Client Contact Last Name Vaildation
    React.useEffect(() => {
        let newselectedCarriersContactsWithLastNameValidation = []
        selectedCarriers?.map(c => {
            return c.Contacts?.map(contact => {
                if (contact.ConnectivityRequestContactLastName.split(" ").join("").length == 0) {
                    newselectedCarriersContactsWithLastNameValidation.push(contact)
                }
            })
        });
        selectedOtherCarriers?.selectedOtherCarriersFullArray?.map(c => {
            return c.Contacts?.map(contact => {
                if (contact.ConnectivityRequestContactLastName.split(" ").join("").length == 0) {
                    newselectedCarriersContactsWithLastNameValidation.push(contact)
                }
            })
        });

        if (newselectedCarriersContactsWithLastNameValidation.length > 0) {
            setSelectCRContactLastNameValidationForm("Please add at least Last Name for each contact at each Carrier");
        }
        else setSelectCRContactLastNameValidationForm("");
    }, [tabValueCallback, JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), thirdPartyContactsLastNameValidationForm])

    // Client Contact type Vaildation
    React.useEffect(() => {
        let newselectedCarriersContactsTypeValidation = []
        selectedCarriers?.map(c => {
            return c.Contacts?.map(contact => {
                if (contact.CarrierContactType.split(" ").join("").length == 0) {
                    newselectedCarriersContactsTypeValidation.push(contact)
                }
            })
        });
        selectedOtherCarriers?.selectedOtherCarriersFullArray?.map(c => {
            return c.Contacts?.map(contact => {
                if (contact.CarrierContactType.split(" ").join("").length == 0) {
                    newselectedCarriersContactsTypeValidation.push(contact)
                }
            })
        });

        if (newselectedCarriersContactsTypeValidation.length > 0) {
            setSelectCRContactTypeValidationForm("Please add at least type for each contact at each Carrier");
        }
        else setSelectCRContactTypeValidationForm("");
    }, [tabValueCallback, JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), thirdPartyContactsTypeValidationForm])

    // CLient Contact Email Validation
    React.useEffect(() => {

        let newselectedCarriersContactsEmailValidation = []
        let newselectedCarriersContactsEmailFormatValidation = []

        selectedCarriers?.map(c => {
            return c.Contacts?.map(contact => {
                if (contact.ConnectivityRequestContactEmail.split(" ").join("").length == 0) {
                    newselectedCarriersContactsEmailValidation.push(contact)
                } else if (!emailPattern.test(contact.ConnectivityRequestContactEmail)) {
                    newselectedCarriersContactsEmailFormatValidation.push(contact)
                }
            })
        });
        selectedOtherCarriers?.selectedOtherCarriersFullArray?.map(c => {
            return c.Contacts?.map(contact => {
                if (contact.ConnectivityRequestContactEmail.split(" ").join("").length == 0) {
                    newselectedCarriersContactsEmailValidation.push(contact)
                } else if (!emailPattern.test(contact.ConnectivityRequestContactEmail)) {
                    newselectedCarriersContactsEmailFormatValidation.push(contact)
                }
            })
        });


        if (newselectedCarriersContactsEmailValidation.length > 0) {
            setSelectCRContactEmailValidationForm("Please add at least Email for each contact at each Carrier");
        }
        else if (newselectedCarriersContactsEmailFormatValidation.length > 0) {
            setSelectCRContactEmailValidationForm("Please add Valid Email for each contact at each Carrier");
        }
        else setSelectCRContactEmailValidationForm("");
    }, [tabValueCallback, JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), thirdPartyContactsEmailValidationForm])

    // //===================================hereeee

    React.useEffect(() => {
        if (!isPEOClicked && selectedCarriers && selectedCarriers?.filter(s => (!s.SelectedCRTypes || s.SelectedCRTypes.length <= 0)).length > 0) {
            setSelectCRTypeValidationForm("Please select at least one connectivity request type for each Carrier selected");
        }
        else if (selectedCarriers && selectedCarriers.filter(s => s.SelectedCRTypes?.includes("Forms"))?.filter(d => d.ConnectivityRequestFormsSubTypeID?.length <= 0 || !d.ConnectivityRequestFormsSubTypeID).length > 0) {
            setSelectCRTypeValidationForm("Please select at least one sub type for each Carrier selected");
        }
        else {
            setSelectCRTypeValidationForm("");
        }
    }, [JSON.stringify(selectedCarriers), selectedCarriers.SelectedCRTypes, selectedCarriers.ConnectivityRequestFormsSubTypeID, selectCRTypeValidationForm])

    React.useEffect(() => {
        if (selectedCarriers && selectedCarriers.filter(s => s.SelectedCRTypes?.includes("Forms"))?.filter(d => d.FormsAttachments?.length <= 0 || !d.FormsAttachments).length > 0) {
            setAttachFormsValidationForm("Please attach at least one form for each form Connectivity Request selected");
        } else {
            setAttachFormsValidationForm("");
        }
    }, [JSON.stringify(selectedCarriers), attachFormsValidationForm])

    React.useEffect(() => {
        if (!migration) setSelectMigrationValidationForm("Migration type is missing, please select a type from the list"); else setSelectMigrationValidationForm("");
    }, [migration, selectMigrationValidationForm])

    React.useEffect(() => {
        if (!migrationStartDate || migrationStartDate === "Invalid date") setSelectMigrationStarDateValidationForm("Migration start date  cannot be empty"); else setSelectMigrationStarDateValidationForm("");
    }, [migrationStartDate, selectMigrationStarDateValidationForm])

    React.useEffect(() => {
        if (!plannedFirstProductionDate || plannedFirstProductionDate === "Invalid date") setSelectPlannedFirstProductionDateValidationForm("Planned first production date  cannot be empty"); else setSelectPlannedFirstProductionDateValidationForm("");
    }, [plannedFirstProductionDate, selectPlannedFirstProductionDateValidationForm])

    React.useEffect(() => {
        if (!migrationPhase) setSelectMigrationPhaseValidationForm("Migration phase is missing, please select a phase from the list"); else setSelectMigrationPhaseValidationForm("");
    }, [migrationPhase, selectMigrationPhaseValidationForm])

    // React.useEffect(() => {
    //     if (!selectedCarriers) return;
    //     if (selectedCarriers?.length > 0 && selectedCarriers?.filter(selectedCarrier => selectedCarrier.FormsAttachments?.length > 0 && selectedCarrier.FormsAttachments?.any(s => !s.MainTypes || s.MainTypes?.length <= 0)).length > 0) setSelectFormMainTypesValidationForm("Missing form main type, please select at least one"); else setSelectFormMainTypesValidationForm("");
    // }, [JSON.stringify(selectedCarriers), selectFormMainTypesValidationForm])

    // React.useEffect(() => {
    //     if (!selectedCarriers) return;
    //     if (selectedCarriers?.length > 0 && selectedCarriers?.filter(selectedCarrier => selectedCarrier.FormsAttachments?.length > 0 && selectedCarrier.FormsAttachments?.any(s => !s.SubTypes || s.SubTypes?.length <= 0)).length > 0) setSelectFormSubTypesValidationForm("Missing form sub type, please select at least one"); else setSelectFormSubTypesValidationForm("");
    // }, [JSON.stringify(selectedCarriers), selectFormSubTypesValidationForm])

    React.useEffect(() => {
        openLoader();
        APIs.getGroupsInstancesAutoComplete({ InstanceUrl: groupsInstanceName ?? "" }).then((r) => !(r?.data?.length) ? setGroupsInstancesByURL([]) : setGroupsInstancesByURL(r?.data));
        setGroupsInstanceAutoValue(groupsInstanceName);
    }, [groupsInstanceName])

    React.useEffect(() => {
        if (groupsInstanceName === "")
            setSelectedGroup("");
    }, [groupsInstanceName])

    React.useEffect(() => {
        openLoader();
        if (!groupsInstanceID) setGroups([]);
        APIs.getISolvedGroups(groupsInstanceID).then((r) => !(r?.data?.length) ? setGroups([]) : setGroups(r?.data?.map((g) => ({ code: g.ClientID, label: g.ClientName, value: g })) ?? []));
    }, [groupsInstanceID]);

    React.useEffect(() => {
        openLoader();
        APIs.getAllGroupsAndOEContacts().then((r) => (
            !(r?.data?.length) ? setGroupsAndOEContactsList([]) : setGroupsAndOEContactsList(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        if (isPEOClicked) {
            setSelectedSubgroups([]);
            if (!selectedGroup || selectedGroup === null) { setSelectedGroupDetails(""); setSubgroups([]); }
            else {
                const singleGroupBody = {
                    InstanceID: groupsInstanceID,
                    ClientID: selectedGroup?.value?.ClientID,
                    ClientCode: selectedGroup?.value?.ClientCode
                }
                APIs.getSingleISolvedGroupDetails(singleGroupBody).then((r) => {
                    setSelectedGroupDetails(r?.data);
                    setSubgroups(r?.data?.SubGroups);
                });
            }
        } else {
            // openLoader();
            setSelectedSubgroups([]);
            if (!selectedGroup || selectedGroup === null) { setSelectedGroupDetails(""); setSelectedGroupCarriers([]); setSubgroups([]); }
            else {
                const singleGroupBody = {
                    InstanceID: groupsInstanceID,
                    ClientID: selectedGroup?.value?.ClientID,
                    ClientCode: selectedGroup?.value?.ClientCode
                }
                APIs.getSingleISolvedGroupDetails(singleGroupBody).then((r) => {
                    setSelectedGroupDetails(r?.data);
                    setSelectedGroupCarriers(r?.data?.Carriers);
                    setSubgroups(r?.data?.SubGroups);
                });
            }
        }
    }, [selectedGroup, isPEOClicked]);

    React.useEffect(() => {
        if (isPEOClicked) {
            setSelectedSubgroups([]);
            if (!selectedGroup || selectedGroup === null) { setSelectedGroupDetails(""); setSubgroups([]); }
            else {
                const singleGroupBody = {
                    InstanceID: groupsInstanceID,
                    ClientID: selectedGroup?.value?.ClientID,
                    ClientCode: selectedGroup?.value?.ClientCode
                }
                APIs.getSingleISolvedGroupDetails(singleGroupBody).then((r) => {
                    setSelectedGroupDetails(r?.data);
                    setSubgroups(r?.data?.SubGroups);
                });
            }
        } else {
            openLoader();
            setSelectedSubgroups([]);
            if (!selectedGroup || selectedGroup === null) { setSelectedGroupDetails(""); setSelectedGroupCarriers([]); setSubgroups([]); }
            else {
                const singleGroupBody = {
                    InstanceID: groupsInstanceID,
                    ClientID: selectedGroup?.value?.ClientID,
                    ClientCode: selectedGroup?.value?.ClientCode
                }
                APIs.getSingleISolvedGroupDetails(singleGroupBody).then((r) => {
                    setSelectedGroupDetails(r?.data);
                    setSelectedGroupCarriers(r?.data?.Carriers);
                    setSubgroups(r?.data?.SubGroups);
                });
            }
        }
    }, [selectedGroup]);

    React.useEffect(() => {
        APIs.getCarriers({}).then(r => setInternalCarriers(r?.data));
    }, [])

    React.useEffect(() => {
        if (groups)
            closeLoader();
        else openLoader()
    }, [groups])

    React.useEffect(() => {
        if ((selectedGroup && selectedGroupDetails) || (!selectedGroup && !selectedGroupDetails))
            closeLoader();
        else openLoader()
    }, [selectedGroupDetails])

    const handleCarrierSearchValueChange = (e) => {
        setCarrierSearchValue(e.target.value);
    }

    const handleContactNotesChange = (e) => {
        setClientContactsNotes(e.target.value);
    }

    const handleSubgroupSearchValueChange = (e) => {
        setSubgroupSearchValue(e.target.value);
    }

    const handleGroupSizeChange = (e) => {
        setGroupSize(e.target.value);
    }

    const handleGroupEffDateChange = (date) => {
        setGroupEffDate(Moment(date).format('YYYY-MM-DD'));
    };

    const handleMigrationStartDateChange = (date) => {
        setMigrationStartDate(Moment(date).format('YYYY-MM-DD'));
    };
    const handlePlannedFirstProductionDateChange = (date) => {
        setPlannedFirstProductionDate(Moment(date).format('YYYY-MM-DD'));
    };

    // Client Contact
    const handleAddClientContact = () => {
        setClientContacts([...clientContacts, {
            GroupRequestContactFirstName: '',
            GroupRequestContactLastName: '',
            GroupRequestContactEmail: '',
            ReceiveNotifications: false
        }])
    }

    React.useEffect(() => {
        selectedCarriers.length = 0;
    }, [selectedGroup])

    React.useEffect(() => {
        setSelectedGroup(null);
    }, [groupsInstanceID])

    // get Migration phases List  apis 
    React.useEffect(() => {
        APIs.getMigrationPhases().then(r => !(r?.data?.length) ? setMigrationPhasesList([]) : setMigrationPhasesList(r?.data));
    }, [])

    const checkAllContactsValidation = () => {
        return selectCRContactValidationForm || selectCRContactFirstNameValidationForm || selectCRContactLastNameValidationForm || selectCRContactEmailValidationForm || selectCRContactTypeValidationForm
    }

    const submitRequest = (e) => {
        e.preventDefault();
        let groupRequest;
        if (requestSubmitted) {
            if ((migration !== "No" && !migrationPhase) || (migration !== "No" && (!migrationStartDate || migrationStartDate === "Invalid date")) || (migration !== "No" && (!plannedFirstProductionDate || plannedFirstProductionDate === "Invalid date"))) return;
            if (isPEOClicked) {
                if (selectClientContactValidationForm || selectClientEffectiveDateValidationForm || selectGroupInstanceValidationForm || selectCarrierValidationForm || carrierCodeValidationForm || carrierNameValidationForm || selectCRTypeValidationForm || selectPlanValidationForm || checkAllContactsValidation() || selectCRHREmailValidationForm || selectMigrationValidationForm || thirdPartyNameValidationForm || selectThirdPartyTypeValidationForm || thirdPartyPlansValidationForm || checkAllThirdPartiesContactsValidation() || thirdPartyEffectiveDateValidationForm || attachFormsValidationForm) return;
                openLoader();
                groupRequest = {
                    GroupInstanceID: groupsInstanceID,
                    GroupRequestStatusID: 1,
                    GroupContact: groupContact,
                    OEContact: OEContact,
                    GroupSize: groupSize,
                    MigrationGroupRequest: migration,
                    MigrationStartDate: migrationStartDate,
                    PlannedFirstProductionDate: plannedFirstProductionDate,
                    MigrationPhaseID: migrationPhase,
                    ClientsContacts: clientContacts,
                    ContactNotes: clientContactsNotes,
                    IsPEO: true,
                    PEO: PEOsList,
                    Carriers: selectedCarriers.map((selectedCarrier, indexSC) => ({
                        CarrierID: isPEOClicked ? selectedCarrier?.CarrierID : selectedCarrier.InternalCarrierID !== null ? selectedCarrier.InternalCarrierID : !(selectedCarrier.CarrierID) && (selectedCarrier.InternalCarrierID === null) ? null : selectedCarrier.CarrierID,
                        PlanTypes: selectedCarrier?.SelectedPlans?.filter(p => p !== `Other`),
                        OtherPlanTypes: selectedCarrier.OtherPlanTypes?.length > 0 ? selectedCarrier.OtherPlanTypes?.split(/\r\n|\r|\n/) : [],
                        ReceivedEncryptionRequirements: selectedCarrier.ReceivedEncryptionRequirements === true ? 1 : 0,
                        ConnectivityRequestTypes: ['EDI'],
                        ConnectivityRequestEDISubTypeID: selectedCarrier.ConnectivityRequestEDISubTypeID,
                        ConnectivityRequestFormsSubTypeID: selectedCarrier.ConnectivityRequestFormsSubTypeID,
                        HREmail: selectedCarrier.HREmail,
                        ClientEffectiveDate: selectedCarrier.ClientEffectiveDate !== "Invalid date" ? selectedCarrier.ClientEffectiveDate : null,
                        // ClientContact: selectedCarrier?.ClientContact,
                        ClientContact: groupContact,
                        CarrierGroupNumber: selectedCarrier?.CarrierGroupNumber ?? null,
                        MigrationGroupRequest: migration,
                        MigrationStartDate: migrationStartDate,
                        PlannedFirstProductionDate: plannedFirstProductionDate,
                        MigrationPhaseID: migrationPhase,
                        IsolvedCarrier: {
                            Code: isPEOClicked ? selectedCarrier?.Code : selectedCarrier?.partnerCode,
                            Name: isPEOClicked ? selectedCarrier?.CarrierName : selectedCarrier?.carrierName,
                            Type: isPEOClicked ? null : selectedCarrier?.carrierType,
                            isNew: !(selectedCarrier.CarrierID) && (selectedCarrier.InternalCarrierID === null) ? true : false
                        },
                        NewAttachments: selectedCarrier.Attachments?.map((file) => ({
                            Type: file.Type,
                            Name: file.Name,
                            Extension: file.Extension,
                            GUID: file.GUID
                        })),

                        DigitalForms: selectedCarrier.FormsAttachments?.map((form) => ({
                            Name: form?.Name,
                            Type: "DigitalForm",
                            GUID: form?.GUID,
                            Extension: form?.Extension,
                            // MainTypesIDs: form?.MainTypes?.map(s => s.Id),
                            // SubTypesIDs: form?.SubTypes?.map(s => s.Id),
                        })),
                        CarriersContacts: selectedCarrier.Contacts ?? []
                    })).concat(selectedOtherCarriers?.selectedOtherCarriersFullArray?.map((selectedCarrier, indexSC) => ({
                        CarrierID: null,
                        PlanTypes: selectedCarrier?.SelectedPlans?.filter(p => p !== `Other`),
                        OtherPlanTypes: selectedCarrier.OtherPlanTypes?.length > 0 ? selectedCarrier.OtherPlanTypes?.split(/\r\n|\r|\n/) : [],
                        ClientEffectiveDate: selectedCarrier.ClientEffectiveDate !== "Invalid date" ? selectedCarrier.ClientEffectiveDate : null,
                        // ClientContact: selectedCarrier?.ClientContact,
                        ClientContact: groupContact,
                        CarrierGroupNumber: selectedCarrier?.CarrierGroupNumber ?? null,
                        MigrationGroupRequest: migration,
                        MigrationStartDate: migrationStartDate,
                        PlannedFirstProductionDate: plannedFirstProductionDate,
                        MigrationPhaseID: migrationPhase,
                        ConnectivityRequestTypes: selectedCarrier.SelectedCRTypes[0] === "No Preference" ? ['EDI'] : selectedCarrier.SelectedCRTypes,
                        ConnectivityRequestEDISubTypeID: selectedCarrier.ConnectivityRequestEDISubTypeID,
                        ConnectivityRequestFormsSubTypeID: selectedCarrier.ConnectivityRequestFormsSubTypeID,
                        HREmail: selectedCarrier.HREmail,
                        ReceivedEncryptionRequirements: selectedCarrier.ReceivedEncryptionRequirements,
                        IsolvedCarrier: {
                            Code: selectedCarrier?.Code,
                            Name: selectedCarrier?.Name,
                            Type: "",
                            isNew: true
                        },
                        NewAttachments: selectedCarrier.Attachments?.map((file) => ({
                            Type: file.Type,
                            Name: file.Name,
                            Extension: file.Extension,
                            GUID: file.GUID
                        })),
                        CarriersContacts: selectedCarrier.Contacts ?? []
                    })) ?? []).concat(selectedThirdParties?.thirdPartiesFullArray?.map((selectedCarrier, indexSC) => ({
                        CarrierID: selectedThirdParties[0].InternalCarrierID ?? null,
                        PlanTypes: selectedCarrier?.SelectedTPPlans?.filter(p => p !== `Other`),
                        ClientEffectiveDate: selectedCarrier.ClientEffectiveDate !== "Invalid date" ? selectedCarrier.ClientEffectiveDate : null,
                        // ClientContact: selectedCarrier?.ClientContact,
                        ClientContact: groupContact,
                        CarrierGroupNumber: selectedCarrier?.CarrierGroupNumber ?? null,
                        MigrationGroupRequest: migration,
                        MigrationStartDate: migrationStartDate,
                        PlannedFirstProductionDate: plannedFirstProductionDate,
                        MigrationPhaseID: migrationPhase,
                        ConnectivityRequestTypes: selectedCarrier.SelectedCRTypes[0] === "No Preference" ? ['EDI'] : selectedCarrier.SelectedCRTypes,
                        ConnectivityRequestEDISubTypeID: selectedCarrier.ConnectivityRequestEDISubTypeID,
                        ConnectivityRequestFormsSubTypeID: selectedCarrier.ConnectivityRequestFormsSubTypeID,
                        HREmail: selectedCarrier.HREmail,
                        ThirdPartyName: selectedCarrier?.ThirdPartyName,
                        OtherPlanTypes: selectedCarrier.OtherTPPlanTypes?.length > 0 ? selectedCarrier.OtherTPPlanTypes?.split(/\r\n|\r|\n/) : [],
                        ReceivedEncryptionRequirements: selectedThirdParties[0].ReceivedEncryptionRequirements,
                        IsolvedCarrier: {
                            Code: selectedThirdParties[0]?.partnerCode,
                            Name: selectedThirdParties[0]?.carrierName,
                            Type: selectedThirdParties[0]?.carrierName,
                            isNew: false
                        },
                        NewAttachments: selectedCarrier.Attachments?.map((file) => ({
                            Type: file.Type,
                            Name: file.Name,
                            Extension: file.Extension,
                            GUID: file.GUID
                        })),
                        DigitalForms: selectedCarrier.FormsAttachments?.map((form) => ({
                            Name: form?.Name,
                            Type: "DigitalForm",
                            GUID: form?.GUID,
                            Extension: form?.Extension,
                            // MainTypesIDs: form?.MainTypes?.map(s => s.Id),
                            // SubTypesIDs: form?.SubTypes?.map(s => s.Id),
                        })),
                        CarriersContacts: selectedCarrier.Contacts ?? []
                    })) ?? []),
                }
            } else {
                if (selectGroupInstanceValidationForm || selectGroupValidationForm || selectClientContactValidationForm || selectClientEffectiveDateValidationForm || selectCarrierValidationForm || selectCRTypeValidationForm || matchCarriersValidationForm || selectPlanValidationForm || checkAllContactsValidation() || selectCRHREmailValidationForm || selectMigrationValidationForm || thirdPartyNameValidationForm || selectThirdPartyTypeValidationForm || thirdPartyPlansValidationForm || checkAllThirdPartiesContactsValidation() || thirdPartyEffectiveDateValidationForm || thirdPartyAttachFormsValidationForm || attachFormsValidationForm) return;
                openLoader();
                groupRequest = {
                    GroupInstanceID: groupsInstanceID,
                    GroupRequestStatusID: 1,
                    GroupContact: groupContact,
                    OEContact: OEContact,
                    GroupSize: groupSize,
                    // GroupEffectiveDate: groupEffDate,
                    MigrationGroupRequest: migration,
                    MigrationStartDate: migrationStartDate,
                    PlannedFirstProductionDate: plannedFirstProductionDate,
                    MigrationPhaseID: migrationPhase,
                    ClientsContacts: clientContacts,
                    ContactNotes: clientContactsNotes,
                    Group: {
                        ClientCode: selectedGroupDetails?.ClientCode,
                        ClientName: selectedGroupDetails?.ClientName,
                        FederalTaxID: selectedGroupDetails?.FederalTaxID,
                        ClientID: `${selectedGroup?.value?.ClientID}`
                    },
                    SubGroups: selectedSubgroups,
                    // LegallyRelatedGroups: [
                    //     {
                    //         ClientCode: "Santeon1",
                    //         ClientName: "Santeon1",
                    //         FederalTaxID: "111111111",
                    //         GroupInstanceID: 19,
                    //         SubGroups: [
                    //             {
                    //                 ClientCode: "dmills-2",
                    //                 ClientName: "Danielle Test Company-2",
                    //                 FederalTaxID: "2222222"
                    //             }
                    //         ]
                    //     },
                    // ],
                    Carriers: selectedCarriers.map((selectedCarrier, indexSC) => ({
                        CarrierID: isPEOClicked ? selectedCarrier?.CarrierID : selectedCarrier.CarrierID !== null ? selectedCarrier.CarrierID : !(selectedCarrier.InternalCarrierID) && (selectedCarrier.CarrierID === null) ? null : selectedCarrier.InternalCarrierID,
                        PlanTypes: selectedCarrier?.SelectedPlans?.filter(p => p !== `Other`),
                        OtherPlanTypes: selectedCarrier.OtherPlanTypes?.length > 0 ? selectedCarrier.OtherPlanTypes?.split(/\r\n|\r|\n/) : [],
                        ReceivedEncryptionRequirements: selectedCarrier.ReceivedEncryptionRequirements === true ? 1 : 0,
                        ConnectivityRequestTypes: selectedCarrier.SelectedCRTypes[0] === "No Preference" ? ['EDI'] : selectedCarrier.SelectedCRTypes,
                        ConnectivityRequestEDISubTypeID: selectedCarrier.ConnectivityRequestEDISubTypeID,
                        ConnectivityRequestFormsSubTypeID: selectedCarrier.ConnectivityRequestFormsSubTypeID,
                        HREmail: selectedCarrier.HREmail,
                        ClientEffectiveDate: selectedCarrier.ClientEffectiveDate !== "Invalid date" ? selectedCarrier.ClientEffectiveDate : null,
                        // ClientContact: selectedCarrier?.ClientContact,
                        ClientContact: groupContact,
                        CarrierGroupNumber: selectedCarrier?.CarrierGroupNumber ?? null,
                        MigrationGroupRequest: migration,
                        MigrationStartDate: migrationStartDate,
                        PlannedFirstProductionDate: plannedFirstProductionDate,
                        MigrationPhaseID: migrationPhase,
                        IsolvedCarrier: {
                            Code: isPEOClicked ? selectedCarrier?.CarrierNumber : selectedCarrier?.partnerCode,
                            Name: isPEOClicked ? selectedCarrier?.CarrierName : selectedCarrier?.carrierName,
                            Type: isPEOClicked ? null : selectedCarrier?.carrierType,
                            isNew: !(selectedCarrier.CarrierID) && (selectedCarrier.InternalCarrierID === null) ? true : false
                        },
                        NewAttachments: selectedCarrier.Attachments?.map((file) => ({
                            Type: file.Type,
                            Name: file.Name,
                            Extension: file.Extension,
                            GUID: file.GUID
                        })),

                        DigitalForms: selectedCarrier.FormsAttachments?.map((form) => ({
                            Name: form?.Name,
                            Type: "DigitalForm",
                            GUID: form?.GUID,
                            Extension: form?.Extension,
                            // MainTypesIDs: form?.MainTypes?.map(s => s.Id),
                            // SubTypesIDs: form?.SubTypes?.map(s => s.Id),
                        })),
                        CarriersContacts: selectedCarrier.Contacts ?? []
                    })).concat(selectedThirdParties?.thirdPartiesFullArray?.map((selectedCarrier, indexSC) => ({
                        CarrierID: selectedThirdParties[0].InternalCarrierID ?? null,
                        PlanTypes: selectedCarrier?.SelectedTPPlans?.filter(p => p !== `Other`),
                        ClientEffectiveDate: selectedCarrier.ClientEffectiveDate !== "Invalid date" ? selectedCarrier.ClientEffectiveDate : null,
                        // ClientContact: selectedCarrier?.ClientContact,
                        ClientContact: groupContact,
                        CarrierGroupNumber: selectedCarrier?.CarrierGroupNumber ?? null,
                        MigrationGroupRequest: migration,
                        MigrationStartDate: migrationStartDate,
                        PlannedFirstProductionDate: plannedFirstProductionDate,
                        MigrationPhaseID: migrationPhase,
                        ConnectivityRequestTypes: selectedCarrier.SelectedCRTypes[0] === "No Preference" ? ['EDI'] : selectedCarrier.SelectedCRTypes,
                        ConnectivityRequestEDISubTypeID: selectedCarrier.ConnectivityRequestEDISubTypeID,
                        ConnectivityRequestFormsSubTypeID: selectedCarrier.ConnectivityRequestFormsSubTypeID,
                        HREmail: selectedCarrier.HREmail,
                        ThirdPartyName: selectedCarrier?.ThirdPartyName,
                        OtherPlanTypes: selectedCarrier.OtherTPPlanTypes?.length > 0 ? selectedCarrier.OtherTPPlanTypes?.split(/\r\n|\r|\n/) : [],
                        ReceivedEncryptionRequirements: selectedThirdParties[0].ReceivedEncryptionRequirements,
                        IsolvedCarrier: {
                            Code: selectedThirdParties[0]?.partnerCode,
                            Name: selectedThirdParties[0]?.carrierName,
                            Type: selectedThirdParties[0]?.carrierName,
                            isNew: false
                        },
                        NewAttachments: selectedCarrier.Attachments?.map((file) => ({
                            Type: file.Type,
                            Name: file.Name,
                            Extension: file.Extension,
                            GUID: file.GUID
                        })),
                        DigitalForms: selectedCarrier.FormsAttachments?.map((form) => ({
                            Name: form?.Name,
                            Type: "DigitalForm",
                            GUID: form?.GUID,
                            Extension: form?.Extension,
                            // MainTypesIDs: form?.MainTypes?.map(s => s.Id),
                            // SubTypesIDs: form?.SubTypes?.map(s => s.Id),
                        })),
                        CarriersContacts: selectedCarrier.Contacts ?? []
                    })) ?? []),
                }
            }

            if (isPEOClicked) {
                APIs.createGroupRequestISolved(groupRequest).then((r) => {
                    if (r?.success === "0") {
                        FailureModal(dialog, {
                            title: 'Failure',
                            body: r?.msg,
                        });
                    } else {
                        routeHistory.push(`/GroupRequests/ViewGroupRequest/${r.GroupRequestID}`)
                    }
                }).catch((s) => FailureModal(dialog, {
                    title: 'Failure',
                    body: 'Error processing request',
                })).finally((t) => closeLoader());
            } else {
                APIs.checkIfDuplicateGroupRequest({
                    GroupClientID: selectedGroup?.value?.ClientID,
                    InstanceID: groupsInstanceID,
                }).then((r) => {
                    closeLoader();
                    if (r?.success === "0") {
                        FailureModal(dialog, {
                            title: 'Failure',
                            body: r?.msg,
                        });
                    } else {
                        if (r?.Duplicate === true) {
                            AlertModal(dialog, () => {
                                openLoader();
                                APIs.createGroupRequestISolved(groupRequest).then((r) => {
                                    if (r?.success === "0") {
                                        FailureModal(dialog, {
                                            title: 'Failure',
                                            body: r?.msg,
                                        });
                                    } else {
                                        routeHistory.push(`/GroupRequests/ViewGroupRequest/${r.GroupRequestID}`)
                                    }
                                }).catch((s) => FailureModal(dialog, {
                                    title: 'Failure',
                                    body: 'Error processing request',
                                })).finally((t) => closeLoader());
                            },
                                {
                                    title: 'Alert! This group request is duplicated!',
                                    info: <div className="text-left fs-12">
                                        Duplicated Group Requests:
                                        {r?.GroupRequestIDs?.map((s, si) =>
                                            <div key={`duplicated-gr-${si}`}>
                                                <a className='warningLink' href={`/GroupRequests/ViewGroupRequest/${s}`} target="_blank" rel="noopener noreferrer">- {s}</a>
                                            </div>
                                        )}
                                    </div>,
                                    question: `Are you sure you want to proceed?`,
                                });
                        } else {
                            APIs.createGroupRequestISolved(groupRequest).then((r) => {
                                if (r?.success === "0") {
                                    FailureModal(dialog, {
                                        title: 'Failure',
                                        body: r?.msg,
                                    });
                                } else {
                                    routeHistory.push(`/GroupRequests/ViewGroupRequest/${r.GroupRequestID}`)
                                }
                            }).catch((s) => FailureModal(dialog, {
                                title: 'Failure',
                                body: 'Error processing request',
                            })).finally((t) => closeLoader());
                        }
                    }
                })
            }

        } else if (requestSaved) {
            if ((migration !== "No" && !migrationPhase) || (migration !== "No" && (!migrationStartDate || migrationStartDate === "Invalid date")) || (migration !== "No" && (!plannedFirstProductionDate || plannedFirstProductionDate === "Invalid date"))) return;
            if (isPEOClicked) {
                if (selectGroupInstanceValidationForm || selectCarrierValidationForm || carrierCodeValidationForm || carrierNameValidationForm || selectCRTypeValidationForm || selectMigrationValidationForm || thirdPartyNameValidationForm || selectThirdPartyTypeValidationForm || thirdPartyPlansValidationForm || checkAllThirdPartiesContactsValidation() || thirdPartyAttachFormsValidationForm || thirdPartyEffectiveDateValidationForm) return;
                openLoader();
                groupRequest = {
                    GroupInstanceID: groupsInstanceID,
                    GroupRequestStatusID: 4,
                    GroupContact: groupContact,
                    OEContact: OEContact,
                    GroupSize: groupSize,
                    MigrationGroupRequest: migration,
                    MigrationStartDate: migrationStartDate,
                    PlannedFirstProductionDate: plannedFirstProductionDate,
                    MigrationPhaseID: migrationPhase,
                    ClientsContacts: clientContacts,
                    ContactNotes: clientContactsNotes,
                    IsPEO: true,
                    PEO: PEOsList,
                    Carriers: selectedCarriers.map((selectedCarrier, indexSC) => ({
                        CarrierID: isPEOClicked ? selectedCarrier?.CarrierID : selectedCarrier.InternalCarrierID !== null ? selectedCarrier.InternalCarrierID : !(selectedCarrier.CarrierID) && (selectedCarrier.InternalCarrierID === null) ? null : selectedCarrier.CarrierID,
                        PlanTypes: selectedCarrier?.SelectedPlans?.filter(p => p !== `Other`),
                        OtherPlanTypes: selectedCarrier.OtherPlanTypes?.length > 0 ? selectedCarrier.OtherPlanTypes?.split(/\r\n|\r|\n/) : [],
                        ReceivedEncryptionRequirements: selectedCarrier.ReceivedEncryptionRequirements === true ? 1 : 0,
                        ConnectivityRequestTypes: selectedCarrier.SelectedCRTypes[0] === "No Preference" ? ['EDI'] : selectedCarrier.SelectedCRTypes,
                        ConnectivityRequestEDISubTypeID: selectedCarrier.ConnectivityRequestEDISubTypeID,
                        ConnectivityRequestFormsSubTypeID: selectedCarrier.ConnectivityRequestFormsSubTypeID,
                        HREmail: selectedCarrier.HREmail,
                        ClientEffectiveDate: selectedCarrier.ClientEffectiveDate !== "Invalid date" ? selectedCarrier.ClientEffectiveDate : null,
                        // ClientContact: selectedCarrier?.ClientContact,
                        ClientContact: groupContact,
                        CarrierGroupNumber: selectedCarrier?.CarrierGroupNumber ?? null,
                        MigrationGroupRequest: migration,
                        MigrationStartDate: migrationStartDate,
                        PlannedFirstProductionDate: plannedFirstProductionDate,
                        MigrationPhaseID: migrationPhase,
                        IsolvedCarrier: {
                            Code: isPEOClicked ? selectedCarrier?.Code : selectedCarrier?.partnerCode,
                            Name: isPEOClicked ? selectedCarrier?.CarrierName : selectedCarrier?.carrierName,
                            Type: isPEOClicked ? null : selectedCarrier?.carrierType,
                            isNew: !(selectedCarrier.CarrierID) && (selectedCarrier.InternalCarrierID === null) ? true : false
                        },
                        NewAttachments: selectedCarrier.Attachments?.map((file) => ({
                            Type: file.Type,
                            Name: file.Name,
                            Extension: file.Extension,
                            GUID: file.GUID
                        })),

                        DigitalForms: selectedCarrier.FormsAttachments?.map((form) => ({
                            Name: form?.Name,
                            Type: "DigitalForm",
                            GUID: form?.GUID,
                            Extension: form?.Extension,
                            // MainTypesIDs: form?.MainTypes?.map(s => s.Id),
                            // SubTypesIDs: form?.SubTypes?.map(s => s.Id),
                        })),
                        CarriersContacts: selectedCarrier.Contacts ?? []
                    })).concat(selectedOtherCarriers?.selectedOtherCarriersFullArray?.map((selectedCarrier, indexSC) => ({
                        // CarrierID: selectedCarrier?.CarrierID ?? null, //will be the Not Listed ID 86
                        CarrierID: null,
                        PlanTypes: selectedCarrier?.SelectedPlans?.filter(p => p !== `Other`),
                        OtherPlanTypes: selectedCarrier.OtherPlanTypes?.length > 0 ? selectedCarrier.OtherPlanTypes?.split(/\r\n|\r|\n/) : [],
                        ClientEffectiveDate: selectedCarrier.ClientEffectiveDate !== "Invalid date" ? selectedCarrier.ClientEffectiveDate : null,
                        // ClientContact: selectedCarrier?.ClientContact,
                        ClientContact: groupContact,
                        CarrierGroupNumber: selectedCarrier?.CarrierGroupNumber ?? null,
                        MigrationGroupRequest: migration,
                        MigrationStartDate: migrationStartDate,
                        PlannedFirstProductionDate: plannedFirstProductionDate,
                        MigrationPhaseID: migrationPhase,
                        ConnectivityRequestTypes: selectedCarrier.SelectedCRTypes[0] === "No Preference" ? ['EDI'] : selectedCarrier.SelectedCRTypes,
                        ConnectivityRequestEDISubTypeID: selectedCarrier.ConnectivityRequestEDISubTypeID,
                        ConnectivityRequestFormsSubTypeID: selectedCarrier.ConnectivityRequestFormsSubTypeID,
                        HREmail: selectedCarrier.HREmail,
                        ReceivedEncryptionRequirements: selectedCarrier.ReceivedEncryptionRequirements,
                        IsolvedCarrier: {
                            Code: selectedCarrier?.Code,
                            Name: selectedCarrier?.Name,
                            Type: "",
                            isNew: true
                        },
                        NewAttachments: selectedCarrier.Attachments?.map((file) => ({
                            Type: file.Type,
                            Name: file.Name,
                            Extension: file.Extension,
                            GUID: file.GUID
                        })),
                        CarriersContacts: selectedCarrier.Contacts ?? []
                    })) ?? []).concat(selectedThirdParties?.thirdPartiesFullArray?.map((selectedCarrier, indexSC) => ({
                        CarrierID: selectedThirdParties[0].InternalCarrierID ?? null,
                        PlanTypes: selectedCarrier?.SelectedTPPlans?.filter(p => p !== `Other`),
                        ClientEffectiveDate: selectedCarrier.ClientEffectiveDate !== "Invalid date" ? selectedCarrier.ClientEffectiveDate : null,
                        // ClientContact: selectedCarrier?.ClientContact,
                        ClientContact: groupContact,
                        CarrierGroupNumber: selectedCarrier?.CarrierGroupNumber ?? null,
                        MigrationGroupRequest: migration,
                        MigrationStartDate: migrationStartDate,
                        PlannedFirstProductionDate: plannedFirstProductionDate,
                        MigrationPhaseID: migrationPhase,
                        ConnectivityRequestTypes: selectedCarrier.SelectedCRTypes[0] === "No Preference" ? ['EDI'] : selectedCarrier.SelectedCRTypes,
                        ConnectivityRequestEDISubTypeID: selectedCarrier.ConnectivityRequestEDISubTypeID,
                        ConnectivityRequestFormsSubTypeID: selectedCarrier.ConnectivityRequestFormsSubTypeID,
                        HREmail: selectedCarrier.HREmail,
                        ThirdPartyName: selectedCarrier?.ThirdPartyName,
                        OtherPlanTypes: selectedCarrier.OtherTPPlanTypes?.length > 0 ? selectedCarrier.OtherTPPlanTypes?.split(/\r\n|\r|\n/) : [],
                        ReceivedEncryptionRequirements: selectedThirdParties[0].ReceivedEncryptionRequirements,
                        IsolvedCarrier: {
                            Code: selectedThirdParties[0]?.partnerCode,
                            Name: selectedThirdParties[0]?.carrierName,
                            Type: selectedThirdParties[0]?.carrierName,
                            isNew: false
                        },
                        NewAttachments: selectedCarrier.Attachments?.map((file) => ({
                            Type: file.Type,
                            Name: file.Name,
                            Extension: file.Extension,
                            GUID: file.GUID
                        })),
                        DigitalForms: selectedCarrier.FormsAttachments?.map((form) => ({
                            Name: form?.Name,
                            Type: "DigitalForm",
                            GUID: form?.GUID,
                            Extension: form?.Extension,
                            // MainTypesIDs: form?.MainTypes?.map(s => s.Id),
                            // SubTypesIDs: form?.SubTypes?.map(s => s.Id),
                        })),
                        CarriersContacts: selectedCarrier.Contacts ?? []
                    })) ?? []),
                }
            } else {
                if (selectGroupInstanceValidationForm || selectGroupValidationForm || selectClientContactValidationForm || selectCarrierValidationForm || selectCRTypeValidationForm || matchCarriersValidationForm || selectMigrationValidationForm || thirdPartyNameValidationForm || selectThirdPartyTypeValidationForm || attachFormsValidationForm) return;
                openLoader();
                groupRequest = {
                    GroupInstanceID: groupsInstanceID,
                    GroupRequestStatusID: 4,
                    GroupContact: groupContact,
                    OEContact: OEContact,
                    GroupSize: groupSize,
                    // GroupEffectiveDate: groupEffDate,
                    MigrationGroupRequest: migration,
                    MigrationStartDate: migrationStartDate,
                    PlannedFirstProductionDate: plannedFirstProductionDate,
                    MigrationPhaseID: migrationPhase,
                    ClientsContacts: clientContacts,
                    ContactNotes: clientContactsNotes,
                    Group: {
                        ClientCode: selectedGroupDetails?.ClientCode,
                        ClientName: selectedGroupDetails?.ClientName,
                        FederalTaxID: selectedGroupDetails?.FederalTaxID,
                        ClientID: `${selectedGroup?.value?.ClientID}`
                    },
                    SubGroups: selectedSubgroups,
                    // LegallyRelatedGroups: [
                    //     {
                    //         ClientCode: "Santeon1",
                    //         ClientName: "Santeon1",
                    //         FederalTaxID: "111111111",
                    //         GroupInstanceID: 19,
                    //         SubGroups: [
                    //             {
                    //                 ClientCode: "dmills-2",
                    //                 ClientName: "Danielle Test Company-2",
                    //                 FederalTaxID: "2222222"
                    //             }
                    //         ]
                    //     },
                    // ],
                    Carriers: selectedCarriers.map((selectedCarrier) => ({
                        CarrierID: selectedCarrier.InternalCarrierID !== null ? selectedCarrier.InternalCarrierID : !(selectedCarrier.CarrierID) && (selectedCarrier.InternalCarrierID === null) ? null : selectedCarrier.CarrierID,
                        PlanTypes: selectedCarrier?.SelectedPlans?.filter(p => p !== `Other`),
                        OtherPlanTypes: selectedCarrier.OtherPlanTypes?.length > 0 ? selectedCarrier.OtherPlanTypes?.split(/\r\n|\r|\n/) : [],
                        ReceivedEncryptionRequirements: selectedCarrier.ReceivedEncryptionRequirements === true ? 1 : 0,
                        ConnectivityRequestTypes: selectedCarrier.SelectedCRTypes[0] === "No Preference" ? ['EDI'] : selectedCarrier.SelectedCRTypes,
                        ConnectivityRequestEDISubTypeID: selectedCarrier.ConnectivityRequestEDISubTypeID,
                        ConnectivityRequestFormsSubTypeID: selectedCarrier.ConnectivityRequestFormsSubTypeID,
                        HREmail: selectedCarrier.HREmail,
                        ClientEffectiveDate: selectedCarrier.ClientEffectiveDate !== "Invalid date" ? selectedCarrier.ClientEffectiveDate : null,
                        ClientContact: groupContact,
                        CarrierGroupNumber: selectedCarrier?.CarrierGroupNumber ?? null,
                        MigrationGroupRequest: migration,
                        MigrationStartDate: migrationStartDate,
                        PlannedFirstProductionDate: plannedFirstProductionDate,
                        MigrationPhaseID: migrationPhase,
                        IsolvedCarrier: {
                            Code: selectedCarrier?.partnerCode,
                            Name: selectedCarrier?.carrierName,
                            Type: selectedCarrier?.carrierType,
                            isNew: !(selectedCarrier.CarrierID) && (selectedCarrier.InternalCarrierID === null) ? true : false
                        },
                        NewAttachments: selectedCarrier.Attachments?.map((file) => ({
                            Type: file.Type,
                            Name: file.Name,
                            Extension: file.Extension,
                            GUID: file.GUID
                        })),
                        DigitalForms: selectedCarrier.FormsAttachments?.map((form) => ({
                            Name: form?.Name,
                            Type: "DigitalForm",
                            GUID: form?.GUID,
                            Extension: form?.Extension,
                            // MainTypesIDs: form?.MainTypes?.map(s => s.Id),
                            // SubTypesIDs: form?.SubTypes?.map(s => s.Id),
                        })),
                        CarriersContacts: selectedCarrier.Contacts ?? []
                    })).concat(selectedThirdParties?.thirdPartiesFullArray?.map((selectedCarrier, indexSC) => ({
                        CarrierID: selectedThirdParties[0].InternalCarrierID ?? null,
                        PlanTypes: selectedCarrier?.SelectedTPPlans?.filter(p => p !== `Other`),
                        OtherPlanTypes: selectedCarrier.OtherTPPlanTypes?.length > 0 ? selectedCarrier.OtherTPPlanTypes?.split(/\r\n|\r|\n/) : [],
                        ConnectivityRequestTypes: selectedCarrier.SelectedCRTypes[0] === "No Preference" ? ['EDI'] : selectedCarrier.SelectedCRTypes,
                        ConnectivityRequestEDISubTypeID: selectedCarrier.ConnectivityRequestEDISubTypeID,
                        ConnectivityRequestFormsSubTypeID: selectedCarrier.ConnectivityRequestFormsSubTypeID,
                        HREmail: selectedCarrier.HREmail,
                        ClientEffectiveDate: selectedCarrier.ClientEffectiveDate !== "Invalid date" ? selectedCarrier.ClientEffectiveDate : null,
                        // ClientContact: selectedCarrier?.ClientContact,
                        ClientContact: groupContact,
                        CarrierGroupNumber: selectedCarrier?.CarrierGroupNumber ?? null,
                        MigrationGroupRequest: migration,
                        MigrationStartDate: migrationStartDate,
                        PlannedFirstProductionDate: plannedFirstProductionDate,
                        MigrationPhaseID: migrationPhase,
                        ThirdPartyName: selectedCarrier?.ThirdPartyName,
                        ReceivedEncryptionRequirements: selectedThirdParties[0].ReceivedEncryptionRequirements,
                        IsolvedCarrier: {
                            Code: selectedThirdParties[0]?.partnerCode,
                            Name: selectedThirdParties[0]?.carrierName,
                            Type: selectedThirdParties[0]?.carrierName,
                            isNew: false
                        },
                        NewAttachments: selectedCarrier.Attachments?.map((file) => ({
                            Type: file.Type,
                            Name: file.Name,
                            Extension: file.Extension,
                            GUID: file.GUID
                        })),
                        DigitalForms: selectedCarrier.FormsAttachments?.map((form) => ({
                            Name: form?.Name,
                            Type: "DigitalForm",
                            GUID: form?.GUID,
                            Extension: form?.Extension,
                            // MainTypesIDs: form?.MainTypes?.map(s => s.Id),
                            // SubTypesIDs: form?.SubTypes?.map(s => s.Id),
                        })),
                        CarriersContacts: selectedCarrier.Contacts ?? []
                    })) ?? []),
                }
            }

            if (isPEOClicked) {
                APIs.createGroupRequestISolved(groupRequest).then((r) => {
                    if (r?.success === "0") {
                        FailureModal(dialog, {
                            title: 'Failure',
                            body: r?.msg,
                        });
                    } else {
                        routeHistory.push(`/GroupRequests/ViewGroupRequest/${r.GroupRequestID}`)
                    }
                }).catch((s) => FailureModal(dialog, {
                    title: 'Failure',
                    body: 'Error processing request',
                })).finally((t) => closeLoader());
            } else {
                APIs.checkIfDuplicateGroupRequest({
                    GroupClientID: selectedGroup?.value?.ClientID,
                    InstanceID: groupsInstanceID,
                }).then((r) => {
                    closeLoader();
                    if (r?.success === "0") {
                        FailureModal(dialog, {
                            title: 'Failure',
                            body: r?.msg,
                        });
                    } else {
                        if (r?.Duplicate === true) {
                            AlertModal(dialog, () => {
                                openLoader();
                                APIs.createGroupRequestISolved(groupRequest).then((r) => {
                                    if (r?.success === "0") {
                                        FailureModal(dialog, {
                                            title: 'Failure',
                                            body: r?.msg,
                                        });
                                    } else {
                                        routeHistory.push(`/GroupRequests/ViewGroupRequest/${r.GroupRequestID}`)
                                    }
                                }).catch((s) => FailureModal(dialog, {
                                    title: 'Failure',
                                    body: 'Error processing request',
                                })).finally((t) => closeLoader());
                            },
                                {
                                    title: 'Alert! This group request is duplicated!',
                                    info: <div className="text-left fs-12">
                                        Duplicated Group Requests:
                                        {r?.GroupRequestIDs?.map(s =>
                                            <>

                                                <a className='warningLink' href={`/GroupRequests/ViewGroupRequest/${s}`} target="_blank" rel="noopener noreferrer">- {s}</a>
                                            </>
                                        )}
                                    </div>,
                                    question: `Are you sure you want to proceed?`,
                                });
                        } else {
                            APIs.createGroupRequestISolved(groupRequest).then((r) => {
                                if (r?.success === "0") {
                                    FailureModal(dialog, {
                                        title: 'Failure',
                                        body: r?.msg,
                                    });
                                } else {
                                    routeHistory.push(`/GroupRequests/ViewGroupRequest/${r.GroupRequestID}`)
                                }
                            }).catch((s) => FailureModal(dialog, {
                                title: 'Failure',
                                body: 'Error processing request',
                            })).finally((t) => closeLoader());
                        }
                    }
                })
            }
        }
    }
    React.useEffect(() => {
        if (migration == "No") {
            setMigrationStartDate(null);
            setPlannedFirstProductionDate(null);
            setMigrationPhase("");
        } else {
            //if there is migration >> default migration phase (Phase 1 - Map Built)
            setMigrationPhase(2)
        }
    }, [migration])

    React.useEffect(() => {
        if (isPEOClicked) {
            setSelectedCarriers([]);
        }
    }, [isPEOClicked])


    return (
        <div id="create-grp-request">
            <BreadcrumbSteps
                breadcrumbTitleCurrent="Create Group Request"
                isCurrent={true}
            />
            <TabsTitles tabTitle={["Create Group Request"]} />
            <Paper elevation={0} className="p-5">
                <form onSubmit={submitRequest}>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            aria-controls="panel2-content"
                            id="panel-header-cr-config"
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            style={{ pointerEvents: 'none' }}
                        >
                            <span>Group Request Information</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                <Grid item xs={12} sm={12} md={6}>

                                    <h6 className="form-title">Instance URL

                                        <span className="text-danger mb-2 ml-2">*</span>
                                    </h6>


                                    <Autocomplete
                                        id="instance-url-dropdown"
                                        options={groupsInstancesByURL}
                                        autoHighlight
                                        inputValue={groupsInstanceAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.InstanceURL}
                                        onChange={(e, value) => {
                                            setGroupsInstanceName(value?.InstanceURL ?? "");
                                            setGroupsInstanceID(value?.InstanceID ?? "");
                                        }}
                                        getOptionSelected={(option, value) => option.InstanceURL === value.InstanceURL}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.InstanceURL}
                                            </React.Fragment>
                                        )}
                                        // filterOptions={filterOptions}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                onChange={e => setGroupsInstanceAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />

                                    <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                    <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectGroupInstanceValidationForm}</div>
                                </Grid>
                                {groupsInstanceName && (
                                    <Grid item xs={12} sm={12} md={6}>

                                        <FormControlLabel
                                            className="mt-3"
                                            control={
                                                <Switch
                                                    checked={isPEOClicked}
                                                    onChange={(e) => setIsPEOClicked(e.target.checked)}
                                                />
                                            }
                                            label="Is PEO?"
                                        />
                                    </Grid>
                                )}

                                {!isPEOClicked && (
                                    <Grid item xs={12} sm={12} md={6}>
                                        {/* Select Group */}

                                        <h6 className="form-title">{isPEOClicked ? `Select PEO` : `Select Client`}

                                            <span className="text-danger mb-2 ml-2">*</span>
                                        </h6>


                                        <Autocomplete
                                            options={groups}
                                            disabled={!groupsInstanceName}
                                            autoHighlight
                                            // inputValue={selectedGroupAutoValue ?? ""}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(e, newValue) => {
                                                setSelectedGroup(newValue);
                                            }}
                                            getOptionSelected={(option, value) => option.label === value.label}
                                            renderOption={(option) => (
                                                <React.Fragment>
                                                    {option.label}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    // onChange={e => setSelectedGroupAutoValue(e.target.value)}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />

                                        <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                        <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectGroupValidationForm}</div>
                                    </Grid>
                                )}

                                {/* Federal Tax ID */}
                                {selectedGroupDetails && (
                                    <Grid item xs={12} sm={12} md={6}>

                                        <h6 className="form-title">Federal Tax ID</h6>


                                        <div className="textfield-div">
                                            {selectedGroupDetails ? selectedGroupDetails.FederalTaxID : ""}
                                        </div>
                                    </Grid>
                                )}
                                {selectedGroupDetails && (
                                    <Grid item xs={12} sm={12} md={6}>

                                        <h6 className="form-title">Client Size</h6>


                                        <input type="number" min="0" className="text-input w-100" value={groupSize} onChange={handleGroupSizeChange} />

                                    </Grid>
                                )}
                                {/* edit Client Effective Date here  */}
                                {/* <div className="col-xl-2 mt-3 alignvh-center">
                                    <h6 className="form-title">Client Effective Date</h6>
                                </div>
                                <div className="col-xl-10 mt-3 text-input w-100" style={{ padding: "0 20px" }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            className="w-100"
                                            autoOk={true}
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="frequency-date"
                                            value={groupEffDate === null ? groupEffDate : parseISO(groupEffDate)}
                                            onChange={handleGroupEffDateChange}
                                            keyboardIcon={<svg viewBox="0 0 20 18" height="20">
                                                <use xlinkHref={`${dateTimeSVG}#dateTimeSVG`}></use>
                                            </svg>}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div> */}



                                {/* PEO Info */}
                                {isPEOClicked && PEOsList && (
                                    <>
                                        <Grid item xs={12} sm={12} md={6}>

                                            <h6 className="form-title">PEO Name</h6>


                                            <div className="textfield-div">
                                                {PEOsList ? PEOsList.peoName : ""}
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>


                                            <h6 className="form-title">PEO FEIN</h6>


                                            <div className="textfield-div">
                                                {PEOsList ? PEOsList.peoFEIN : ""}
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>


                                            <h6 className="form-title">PEO Size</h6>


                                            <input type="number" min="0" className="text-input w-100" value={groupSize} onChange={handleGroupSizeChange} />

                                        </Grid>
                                    </>
                                )}

                                {/* Subgroups */}
                                {subgroups?.length > 0 && (
                                    <Grid item xs={12} sm={12} md={6}>

                                        <h6 className="form-title">Select Subgroups</h6>
                                        {/* <span className="text-danger mb-2 ml-2">*</span> */}

                                        <input type="text" disabled={!selectedGroup} className="text-input w-100" placeholder="Search subgroups here..." value={subgroupSearchValue} onChange={handleSubgroupSearchValueChange} />


                                        <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted}></div>
                                        <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>{selectSubgroupValidationForm}</div>


                                        <SelectSubgroups subgroups={subgroups} setSelectedSubgroups={setSelectedSubgroups} selectedSubgroups={selectedSubgroups} subgroupSearchValue={subgroupSearchValue} />




                                        <div className="counter-external-div alignvh-center">
                                            <div style={{ padding: "8px 8px 5px 8px" }}>
                                                {selectedSubgroups?.map((s, si) => (
                                                    <TruncatedChip
                                                        style={{ marginBottom: "3px", marginRight: "1px" }}
                                                        key={`selected-subgroup-chip-${si}`}
                                                        body={s.ClientName}
                                                    />
                                                ))}
                                            </div>
                                            <div className="text-right ml-auto alignvh-center mr-2 pt-1 pb-1">
                                                <div style={{ whiteSpace: "nowrap" }} className="counter-div">{selectedSubgroups?.length > 1 ? `${selectedSubgroups?.length} Subgroups Selected` : `${selectedSubgroups?.length} Subgroup Selected`}</div>
                                            </div>
                                        </div>

                                    </Grid>
                                )}

                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">{!isPEOClicked ? 'iSolved Contact' : 'PEO Contact'}

                                        <span className="text-danger mb-2 ml-2">*</span>
                                    </h6>


                                    <FormControl label="Select iSolved contact" focused={false} variant="outlined" className="w-100">
                                        <Select
                                            className="text-dropdown"
                                            value={groupContact ?? ""}
                                            onChange={e => setGroupContact(e.target.value)}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {groupsAndOEContactsList && groupsAndOEContactsList?.filter(s => s.IsEnabled === 1)?.map((c, ci) => (
                                                <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                    <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectClientContactValidationForm}</div>
                                </Grid>


                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">OE Contact</h6>


                                    <FormControl label="Select OE contact" focused={false} variant="outlined" className="w-100">
                                        <Select
                                            className="text-dropdown"
                                            value={OEContact ?? ""}
                                            onChange={e => setOEContact(e.target.value)}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {groupsAndOEContactsList && groupsAndOEContactsList?.filter(s => s.IsEnabled === 1)?.map((c, ci) => (
                                                <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Migration
                                        <span className="text-danger mb-2 ml-2">*</span>
                                    </h6>

                                    <FormControl label="Select Migration" focused={false} variant="outlined" className="w-100">
                                        <Select
                                            className="text-dropdown"
                                            value={migration ?? ""}
                                            onChange={e => setMigration(e.target.value)}
                                        >
                                            <MenuItem value="No">No</MenuItem>
                                            <MenuItem value="eBenefits Network">eBenefits Network</MenuItem>
                                            <MenuItem value="Everything Benefits">Everything Benefits</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                    <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectMigrationValidationForm}</div>
                                </Grid>

                                {/* Migration Added Field*/}
                                {(migration === "eBenefits Network" || migration === "Everything Benefits") &&
                                    <>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">Migration Start Date
                                                <span className="text-danger mb-2 ml-2">*</span>
                                            </h6>
                                            <div className="text-input" style={{ padding: "0 20px" }}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        className="w-100"
                                                        autoOk={true}
                                                        disableToolbar
                                                        variant="inline"
                                                        format="MM/dd/yyyy"
                                                        margin="normal"
                                                        id="migrationStart-date"
                                                        value={migrationStartDate === null ? migrationStartDate : parseISO(migrationStartDate)}
                                                        onChange={handleMigrationStartDateChange}
                                                        keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                            <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                        </svg>}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                            <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectMigrationStarDateValidationForm}</div>
                                        </Grid>
                                        {/* error  */}


                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">Planned First Production Date
                                                <span className="text-danger mb-2 ml-2">*</span></h6>

                                            <div className="text-input" style={{ padding: "0 20px" }}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        className="w-100"
                                                        autoOk={true}
                                                        disableToolbar
                                                        variant="inline"
                                                        format="MM/dd/yyyy"
                                                        margin="normal"
                                                        id="migrationStart-date"
                                                        value={plannedFirstProductionDate === null ? plannedFirstProductionDate : parseISO(plannedFirstProductionDate)}
                                                        onChange={handlePlannedFirstProductionDateChange}
                                                        keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                            <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                        </svg>}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                            <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectPlannedFirstProductionDateValidationForm}</div>
                                        </Grid>

                                        {/* error  */}


                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">Migration Phase
                                                <span className="text-danger mb-2 ml-2">*</span>
                                            </h6>

                                            <FormControl label="Select migration phase" focused={false} variant="outlined" className="w-100">
                                                <Select
                                                    className="text-dropdown"
                                                    value={migrationPhase ?? ""}
                                                    onChange={e => setMigrationPhase(e.target.value)}
                                                >
                                                    {migrationPhasesList && migrationPhasesList?.map((c, ci) => (
                                                        <MenuItem key={`migration-phase-${ci}`} value={c.Id}>{c.Name}</MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>
                                            <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                            <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectMigrationPhaseValidationForm}</div>
                                        </Grid>

                                    </>
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    {/* Client contacts */}
                    {/* <div className="col-xl-2 mt-3 alignvh-center">
                            <h6 className="form-title">Client Contacts</h6>
                        </div> */}
                    <Grid item xs={12} className="mt-5">
                        <CreateGroupRequestClientContacts
                            clientContacts={clientContacts}
                            handleAddClientContact={handleAddClientContact}
                            forceUpdate={forceUpdate}
                            expanded={expanded}
                        />
                    </Grid>


                    <Grid item xs={12} className="mt-3">
                        <h6 className="form-title">Contact Notes</h6>
                        <textarea type="text" className="action-item-textarea w-100" placeholder="Write Contact Notes here..." value={clientContactsNotes} onChange={handleContactNotesChange} />
                    </Grid>


                    {/* Carriers */}
                    <Accordion defaultExpanded>
                        <AccordionSummary>
                            <span className="form-title">Select Carriers

                            </span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                <Grid item xs={12} sm={12} md={6}>



                                    <input type="text" disabled={!selectedGroup} className="text-input w-100" placeholder="Search carriers here..." value={carrierSearchValue} onChange={handleCarrierSearchValueChange} />


                                    <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                    <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectCarrierValidationForm}</div>
                                    <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                    <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{matchCarriersValidationForm}</div>
                                </Grid>
                                {/* <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted}></div>
                        <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>{selectPlanValidationForm}</div> */}
                                {isPEOClicked ? (
                                    <>
                                        <Grid item xs={12}>

                                            <SelectVerifiedCarriers onCarrierNotListedChecked={(s) => setCarrierNotListedChecked(s)} parentForceUpdate={forceUpdate} carriers={internalCarriers} onSelectedOtherCarriersChecked={(d) => setSelectedOtherCarriersChecked(d)} setSelectedCarriers={setSelectedCarriers} selectedCarriers={selectedCarriers} selectedOtherCarriers={selectedOtherCarriers} setSelectedOtherCarriers={setSelectedOtherCarriers} carrierSearchValue={carrierSearchValue} onIndexCallback={s => setIndexCallback(s)} onInternalCarrier={setInternalCarrier} />
                                        </Grid>
                                    </>
                                ) : (
                                    <>

                                        <Grid item xs={12}>
                                            <SelectCarriersAndPlans parentForceUpdate={forceUpdate} carriers={selectedGroupCarriers} setSelectedCarriers={setSelectedCarriers} selectedCarriers={selectedCarriers} selectedThirdParties={selectedThirdParties} setSelectedThirdParties={setSelectedThirdParties} carrierSearchValue={carrierSearchValue} onIndexCallback={s => setIndexCallback(s)} onInternalCarrier={setInternalCarrier} />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    {selectedCarriers.length > 0 && (
                        <>
                            <div className="col-xl-2 mt-2"></div>
                            <Grid item xs={12} className="mt-5">
                                <div className="counter-external-div alignvh-center">
                                    <div style={{ padding: "8px 8px 5px 8px" }}>
                                        {selectedCarriers?.map((s, si) => (
                                            <TruncatedChip
                                                style={{ marginBottom: "3px", marginRight: "1px" }}
                                                key={`selected-carrier-chip-${si}`}
                                                body={isPEOClicked ? s.CarrierName : s.carrierName}
                                            />
                                        ))}
                                    </div>
                                    <div className="text-right ml-auto alignvh-center mr-2 pt-1 pb-1">
                                        <div style={{ whiteSpace: "nowrap" }} className="counter-div">{selectedCarriers.length > 1 ? `${selectedCarriers.length} Carriers Selected` : `${selectedCarriers.length} Carrier Selected`}</div>
                                    </div>
                                </div>
                            </Grid>

                            {selectedCarriers.length > 0 && (
                                <>
                                    <Grid item xs={12} className="mt-5">
                                        <Accordion defaultExpanded>
                                            <AccordionSummary
                                                aria-controls="panel2-content"
                                                id="panel-header-cr-config"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                }}
                                                style={{ pointerEvents: 'none' }}
                                            >
                                                <span>Mapped Carrier</span>
                                            </AccordionSummary>
                                            <div className="col-xl-2 alignvh-center"></div>

                                            <div className="row table-header">
                                                <div className="col-6 table-cell first-cell">isolved Carriers</div>
                                                <div className="col-6 table-cell">Standard Carriers</div>
                                            </div>
                                            {selectedCarriers?.map((s, si) => (
                                                
                                                    <div key={`mapped-carriers-table-${si}`} className="row table-row">
                                                        <div className="col-6 table-cell first-cell">
                                                            {s?.carrierName}
                                                        </div>
                                                        <div className="col-6 table-cell">
                                                            {s?.isOtherChecked === true ? "Other" : s?.CarrierInternalName ? s?.CarrierInternalName : s?.InternalCarrierName}
                                                        </div>
                                                    </div>
                                                
                                            ))}
                                        </Accordion>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}

                    {isPEOClicked && (selectedCarriers || selectedOtherCarriersChecked) && (
                        <>
                            <div className="mt-5">
                                <SelectCarrierDetails
                                    selectedOtherCarriers={selectedOtherCarriers}
                                    parentForceUpdate={forceUpdate}
                                    requestSubmitted={requestSubmitted}
                                    requestSaved={requestSaved}
                                    setSelectedCarriers={setSelectedCarriers}
                                    selectedCarriers={selectedCarriers}
                                    carrierNotListedChecked={carrierNotListedChecked}
                                    carrierCodeValidationForm={carrierCodeValidationForm}
                                    carrierNameValidationForm={carrierNameValidationForm}
                                />
                            </div>
                        </>
                    )}



                    {((selectedCarriers && selectedCarriers.length > 0) || (selectedOtherCarriersChecked)) && (
                        <>

                            <Grid item xs={12} className="mt-5">
                                <SelectConnectivityType isPEOClicked={isPEOClicked} selectedOtherCarriers={selectedOtherCarriers} selectCRTypeValidationForm={selectCRTypeValidationForm} requestSubmitted={requestSubmitted} requestSaved={requestSaved} parentForceUpdate={forceUpdate} carriers={selectedGroupCarriers} setSelectedCarriers={setSelectedCarriers} selectedCarriers={selectedCarriers} subTypeList={subTypeList} setSubTypeList={setSubTypeList} formsSubTypeList={formsSubTypeList} setFormsSubTypeList={setFormsSubTypeList} selectCRHREmailValidationForm={selectCRHREmailValidationForm} attachFormsValidationForm={attachFormsValidationForm} />
                            </Grid>
                        </>
                    )}


                    {/* Plans */}
                    {((selectedCarriers && selectedCarriers.length > 0) || (selectedOtherCarriersChecked)) && (
                        <>
                            <Grid item xs={12} className="mt-5">

                                <SelectCarrierPlans isPEOClicked={isPEOClicked} selectedOtherCarriers={selectedOtherCarriers} selectPlanValidationForm={selectPlanValidationForm} requestSubmitted={requestSubmitted} parentForceUpdate={forceUpdate} carriers={selectedGroupCarriers} setSelectedCarriers={setSelectedCarriers} selectedCarriers={selectedCarriers} />
                            </Grid>
                        </>
                    )}


                    {/* Select Client Effective Date Internal */}
                    {((selectedCarriers && selectedCarriers.length > 0) || (selectedOtherCarriersChecked)) && (
                        <>
                            <div className="col-xl-2 mt-3 alignvh-center"></div>

                            <Grid item xs={12} className="mt-5">
                                <SelectClientInformation
                                    groupReq={false}
                                    isPEOClicked={isPEOClicked}
                                    selectedOtherCarriers={selectedOtherCarriers}
                                    requestSubmitted={requestSubmitted}
                                    requestSaved={requestSaved}
                                    parentForceUpdate={forceUpdate}
                                    carriers={selectedGroupCarriers}
                                    setSelectedCarriers={setSelectedCarriers}
                                    selectedCarriers={selectedCarriers}
                                    groupsAndOEContactsList={groupsAndOEContactsList}
                                    onSelectClientContactValidationForm={s => setSelectClientContactValidationForm(s)}
                                    onSelectClientEffectiveDateValidationForm={s => setSelectClientEffectiveDateValidationForm(s)}
                                    selectClientContactValidationForm={selectClientContactValidationForm}
                                    selectClientEffectiveDateValidationForm={selectClientEffectiveDateValidationForm}
                                />
                            </Grid>
                        </>
                    )}


                    {/* {selectedCarriers && selectedCarriers.length > 0 && (
                            <>
                                <div className="col-xl-2 mt-3 alignvh-center"></div>
                                <div className="col-xl-10 pr-0 pl-0 mt-5">
                                    <UserAssignement selectedCarriers={selectedCarriers} />
                                </div>
                            </>
                        )} */}

                    {/* Contacts */}
                    {((selectedCarriers && selectedCarriers.length > 0) || (selectedOtherCarriersChecked)) && (
                        <>
                            <div className="col-xl-2 mt-3 alignvh-center"></div>
                            <Grid item xs={12} className="mt-5">
                                <CarrierContacts
                                    onTabValueCallback={s => setTabValueCallback(s)}

                                    selectCRContactValidationForm={selectCRContactValidationForm}
                                    selectCRContactFirstNameValidationForm={selectCRContactFirstNameValidationForm}
                                    selectCRContactLastNameValidationForm={selectCRContactLastNameValidationForm}
                                    selectCRContactEmailValidationForm={selectCRContactEmailValidationForm}
                                    selectCRContactTypeValidationForm={selectCRContactTypeValidationForm}

                                    carrierContactTypes={carrierContactTypes}
                                    isPEOClicked={isPEOClicked}
                                    selectedOtherCarriers={selectedOtherCarriers}
                                    parentForceUpdate={forceUpdate}
                                    inputsSavedValidation={inputsSavedValidation}
                                    setInputsSavedValidation={setInputsSavedValidation}
                                    contactValidationCallback={contactValidationCallback}
                                    setContactValidationCallback={setContactValidationCallback}
                                    selectedCarriers={selectedCarriers}
                                    requestSubmitted={requestSubmitted}
                                />
                            </Grid>
                        </>
                    )}

                    {/* Files Attachments */}
                    {((selectedCarriers && selectedCarriers.length > 0) || (selectedOtherCarriersChecked)) && (
                        <>
                            <div className="col-xl-2 mt-3 alignvh-center"></div>
                            <Grid item xs={12} className="mt-5">
                                <FilesAttachments selectedCarriers={selectedCarriers} isPEOClicked={isPEOClicked} selectedOtherCarriers={selectedOtherCarriers?.selectedOtherCarriersFullArray} parentForceUpdate={forceUpdate} />
                            </Grid>
                        </>
                    )}

                    {selectedThirdParties && selectedThirdParties.length > 0 && (
                        <>
                            <div className="col-xl-2 mt-3 alignvh-center"></div>
                            <Grid item xs={12} className="mt-5">
                                <CreateThirdParty
                                    selectedThirdParties={selectedThirdParties}
                                    parentForceUpdate={forceUpdate}
                                    selectPlanValidationForm={selectPlanValidationForm}
                                    onSelectClientContactValidationForm={s => setSelectClientContactValidationForm(s)}
                                    onSelectClientEffectiveDateValidationForm={s => setThirdPartyEffectiveDateValidationForm(s)}
                                    onThirdPartyNameValidationForm={s => setThirdPartyNameValidationForm(s)}
                                    onThirdPartyPlansValidationForm={s => setThirdPartyPlansValidationForm(s)}

                                    onThirdPartyContactsValidationForm={s => setThirdPartyContactsValidationForm(s)}
                                    onThirdPartyContactsFirstNameValidationForm={s => setThirdPartyContactsFirstNameValidationForm(s)}
                                    onThirdPartyContactsLastNameValidationForm={s => setThirdPartyContactsLastNameValidationForm(s)}
                                    onThirdPartyContactsEmailValidationForm={s => setThirdPartyContactsEmailValidationForm(s)}
                                    onThirdPartyContactsTypeValidationForm={s => setThirdPartyContactsTypeValidationForm(s)}

                                    onSelectTPMigrationValidationForm={s => setSelectMigrationValidationForm(s)}
                                    onSelectTPMigrationStarDateValidationForm={s => setSelectPlannedFirstProductionDateValidationForm(s)}
                                    onSelectTPMigrationPhaseValidationForm={s => setSelectMigrationPhaseValidationForm(s)}
                                    onSelectTPPlannedFirstProductionDateValidationForm={s => setSelectPlannedFirstProductionDateValidationForm(s)}
                                    requestSubmitted={requestSubmitted}
                                    requestSaved={requestSaved}
                                    groupsAndOEContactsList={groupsAndOEContactsList}
                                    carrierContactTypes={carrierContactTypes}
                                    isPEOClicked={isPEOClicked}
                                    subTypeList={subTypeList}
                                    setSubTypeList={setSubTypeList}
                                    formsSubTypeList={formsSubTypeList}
                                    setFormsSubTypeList={setFormsSubTypeList}
                                    onThirdPartyTypeValidationForm={s => setSelectThirdPartyTypeValidationForm(s)}
                                    OnThirdPartyAttachFormsValidationForm={s => setThirdPartyAttachFormsValidationForm(s)}

                                />
                            </Grid>
                        </>
                    )}

                    {/* Forms Attachments
                        {selectedCarriers && selectedCarriers.length > 0 && selectedCarriers.any(s => s.SelectedCRTypes?.filter(x => x === "Forms").length > 0) && (
                            <>
                                <div className="col-xl-2 mt-3 alignvh-center"></div>
                                <div className="col-xl-10 pr-0 pl-0 mt-5">
                                    <FormsAttachments
                                        selectedCarriers={selectedCarriers}
                                        requestSubmitted={requestSubmitted}
                                        requestSaved={requestSaved}
                                        // onSelectFormMainTypesValidationForm={(s) => setSelectFormMainTypesValidationForm(s)}
                                        // selectFormMainTypesValidationForm={selectFormMainTypesValidationForm}
                                        // onSelectFormSubTypesValidationForm={(s) => setSelectFormSubTypesValidationForm(s)}
                                        // selectFormSubTypesValidationForm={selectFormSubTypesValidationForm}
                                        attachFormsValidationForm={attachFormsValidationForm}
                                        parentForceUpdate={forceUpdate}
                                    />
                                </div>
                            </>
                        )} */}




                    <div className="w-100 text-right mt-5">
                        <Button type="submit" variant="contained" className="blue-btn mr-3" onClick={() => { setRequestSaved(true); setRequestSubmitted(false); }}>Save Draft</Button>
                        <Button type="submit" variant="contained" className="blue-btn" onClick={() => { setRequestSubmitted(true); setRequestSaved(false); }}>Submit Request</Button>
                    </div>

                </form>

            </Paper>
        </div>
    );
}

export default CreateGroupRequest;
