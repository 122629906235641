import React, { Fragment } from 'react'
import RelationshipContactsTableRow from './RelationshipContactsTableRow'

const RelationshipContactsTable = ({ carrierRelationshipContacts, mode = "view", formikObject, onEdit, onSetEdit, ...props }) => {
    // mode : view , add , edit
    return (
        <div>
            <div className="row table-header">
                <div className="col px-0">
                    <div className="row">
                        <div className="col-2 table-cell first-cell">
                            First Name
                        </div>
                        <div className="col-2 table-cell">
                            Last Name
                        </div>
                        <div className="col-2 table-cell">
                            Job Title
                        </div>
                        <div className="col-2 table-cell">
                            Email
                        </div>
                        <div className="col-2 table-cell">
                            Phone Number
                        </div>
                        <div className="col-2 table-cell">
                            Notes
                        </div>

                    </div>
                </div>
                { (mode==="add" || onEdit) && ( 
                <div className="col-1 px-0">
                    <div className="table-cell">
                        Actions
                    </div>
                </div>
                )}
            </div>

            {
                carrierRelationshipContacts?.length > 0 ?
                    carrierRelationshipContacts?.map((cc, cci) =>
                        <Fragment key={cci}>
                            <RelationshipContactsTableRow carrierRelationshipContact={cc} index={cci} mode={mode} formikObject={formikObject} onEdit={onEdit} onSetEdit={onSetEdit}/>
                        </Fragment>) :
                    <div className="no-data-alert">
                        <h6>No Relationship Contacts Added!</h6>
                    </div>

            }
        </div>
    )
}

export default RelationshipContactsTable
