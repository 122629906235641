import React, { useEffect } from "react";
import { Divider } from "@material-ui/core";
import * as APIs from "../../../Data/APIs";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Backdrop, CircularProgress, Button } from "@material-ui/core";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";


function MapInternalCarrier({ iSolvedCarrier, forceCarrierListUpdate, ...props }) {

    const [internalCarriers, setInternalCarriers] = React.useState([]);
    const [selectedInternalCarrierObject, setSelectedInternalCarrierObject] = React.useState({
        CarrierInternalName: iSolvedCarrier?.CarrierInternalName,
        isOtherChecked: false,
        CarrierID: iSolvedCarrier?.CarrierID
    });
    const [mappingSearchValue,setMappingSearchValue]=React.useState("");
    const other = "other";

    const dialog = useModalDialog(0);

    React.useEffect(() => {
        APIs.getCarriers({}).then(r => setInternalCarriers(r?.data));
    }, [])

    const handleSelectedInternalCarrierChange = (e) => {

        if (selectedInternalCarrierObject.CarrierInternalName === "Other") {
            iSolvedCarrier.CarrierID = null;
            iSolvedCarrier.isOtherChecked = true;
            iSolvedCarrier.CarrierInternalName = "Other";
        } else {
            iSolvedCarrier.CarrierInternalName = selectedInternalCarrierObject?.CarrierName;
            iSolvedCarrier.CarrierID = parseInt(selectedInternalCarrierObject?.CarrierID);
            iSolvedCarrier.isOtherChecked = false;
        }
        if (forceCarrierListUpdate) forceCarrierListUpdate();
    };
    const handleMappingSearchValueChange=(e)=>{
        setMappingSearchValue(e.target.value);
    }
    return (
        <div id="last-sent-file-modal">
        
            {internalCarriers?.length > 0 ?
                <FormControl component="fieldset">
                    <input type="text" className="text-input w-100" placeholder="Search carriers here..." value={mappingSearchValue} onChange={handleMappingSearchValueChange} />
                    <RadioGroup aria-label="internalCarrier" name="internalCarrier" className="internalCarrier-radio-group" value={iSolvedCarrier?.CarrierID ?? ""}>
                        {/* filter for mapping search value */}
                        {internalCarriers.filter(c => mappingSearchValue ? c.CarrierName?.toLowerCase().includes(mappingSearchValue.toLowerCase()) : c).map((c, ci) => (
                            <FormControlLabel key={`internal-carrier-${ci}`} value={c?.CarrierID} control={
                                <Radio onClick={(e) => {
                                    if (c?.CarrierInternalName !== "Other") {
                                        setSelectedInternalCarrierObject(c)
                                    } else {
                                        setSelectedInternalCarrierObject({
                                            CarrierInternalName: 'Other',
                                            isOtherChecked: true,
                                            CarrierID: null
                                        })
                                    }
                                }
                                }
                                    checked={iSolvedCarrier?.CarrierInternalName !== "Other" && (selectedInternalCarrierObject?.CarrierID === c?.CarrierID)} />
                            }
                                label={c?.CarrierName} />
                        ))}
                        
                        {/* only show other option in case of searching on it or not searching at all*/}
                        {internalCarriers.length > 0  && other.slice(0,mappingSearchValue.length).toLowerCase() === mappingSearchValue.toLowerCase()
                        &&(
                            <FormControlLabel value="Other"
                                control={<Radio
                                    onClick={() => {
                                        setSelectedInternalCarrierObject({
                                            CarrierInternalName: "Other",
                                            isOtherChecked: true,
                                            CarrierID: null
                                        })
                                    }}
                                    checked={selectedInternalCarrierObject?.CarrierInternalName == "Other" || iSolvedCarrier?.CarrierInternalName == "Other"}
                                />} label="Other" />
                        )}
                    </RadioGroup>
                </FormControl> :
                <div className="fs-12" style={{ textTransform: "initial", fontWeight: "400", textAlign: "center" }}>
                    <br />
                    Please wait ......
                    <br />
                    <br />
                    <CircularProgress size="4rem" color="inherit" />
                </div>}
            <div className="row w-100 mt-5 custom-internal-mapping-actions" style={{ justifyContent: "center" }}>
                <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => dialog.closeDialog()}>Cancel</Button>
                <Button variant="contained" className="blue-btn" disabled={!selectedInternalCarrierObject} onClick={() => {
                    handleSelectedInternalCarrierChange();
                    dialog.closeDialog();
                }} >Map Selected</Button>
            </div>
        </div>
    )
}

export default MapInternalCarrier;