import React from 'react'
import GenericFormikInput from '../../../../GenericFormikInput'
import { Button, Zoom } from '@material-ui/core'
import deleteForeverSVG from "../../../../../Icons/trash-xmark.svg";
import cancelIconSVG from "../../../../../Icons/CancelIcon.svg";
import { CustomTooltip } from '../../../../Layout/Tooltip';
import { useModalDialog } from "../../../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../../../Modals/ConfirmModal";
import { SuccessModal } from "../../../../Modals/SuccessModal";
import { FailureModal } from "../../../../Modals/FailureModal";

const RelationshipContactsTableRow = ({ carrierRelationshipContact, index, mode, formikObject, onEdit, onSetEdit, ...props }) => {
    const { touched, errors, values, setFieldValue, handleBlur, submitCount } = formikObject || {}
    const dialog = useModalDialog(0);

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }

    const handleDeleteContactConfirm = async () => {
        openModal(0, async (e) => {
            setFieldValue(`CarrierRelationshipContacts[${index}].isDelete`, 1);
        }, {
            title: 'Please Confirm',
            question: `Are you sure you want to delete this Contact?`,
        })
    }

    return (
        values?.CarrierRelationshipContacts[index]?.isDelete !== 1 ? 
        <div className="row table-row" style={{ verticalAlign: 'middle' }}>
            <div className="col px-0">
                <div className="row">
                    <div className="col-2 table-cell first-cell d-flex flex-column word-break-all">
                    
                        <GenericFormikInput
                            name={`CarrierRelationshipContacts[${index}].FirstName`}
                            type={'text'}
                            placeholder='First Name'
                            isRequired={true}
                            isReadOnly={mode!=="add" && !onEdit}
                            readOnlyOptions={
                                {valueToShow: carrierRelationshipContact?.FirstName,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                        />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">                    
                    
                    <GenericFormikInput
                                name={`CarrierRelationshipContacts[${index}].LastName`}
                                type={'text'}
                                placeholder='Last Name'
                                isRequired={true}
                                isReadOnly={mode!=="add" && !onEdit}
                                readOnlyOptions={
                                {valueToShow: carrierRelationshipContact?.LastName,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                            />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                    
                            <GenericFormikInput
                                name={`CarrierRelationshipContacts[${index}].JobTitle`}
                                type={'text'}
                                placeholder='Job Title'
                                isRequired={false}
                                isReadOnly={mode!=="add" && !onEdit}
                                readOnlyOptions={
                                {valueToShow: carrierRelationshipContact?.JobTitle,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                            />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                    
                            <GenericFormikInput
                                name={`CarrierRelationshipContacts[${index}].Email`}
                                type={'text'}
                                placeholder='Email'
                                isRequired={true}
                                isReadOnly={mode!=="add" && !onEdit}
                                readOnlyOptions={
                                {valueToShow: carrierRelationshipContact?.Email,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                            />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                    
                            <GenericFormikInput
                                name={`CarrierRelationshipContacts[${index}].PhoneNumber`}
                                type={'text'}
                                placeholder='Phone Number'
                                isRequired={false}
                                isReadOnly={mode!=="add" && !onEdit}
                                readOnlyOptions={
                                {valueToShow: carrierRelationshipContact?.PhoneNumber,
                                    extraCssClasses: "remove-textfield-div-style" 
                                }
                            }
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                            />
                    </div>
                    <div className="col-2 table-cell d-flex flex-column word-break-all">
                            <GenericFormikInput
                                name={`CarrierRelationshipContacts[${index}].Notes`}
                                type={'textArea'}
                                placeholder='Notes'
                                isRequired={false}
                                isReadOnly={mode!=="add" && !onEdit}
                                readOnlyOptions={
                                {valueToShow: carrierRelationshipContact?.Notes,
                                    extraCssClasses: "remove-textfield-div-style preformatted-text flex justify-content-left"
                                }
                            }
                            style={{ justifyContent: 'left' }}
                                formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                            />
                    </div>
                </div>
            </div>
            { (mode === "add" || onEdit) && ( 
    <div className="col-1 d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
        { (mode === "add" || (onEdit && !(carrierRelationshipContact?.RelationshipContactID))) && (
            <CustomTooltip TransitionComponent={Zoom} title="Cancel">
                <Button
                    role="button"
                    className="icon-btn m-auto"
                    style={{ pointerEvents: "auto"  }}
                    onClick={(e) => {
                        e.stopPropagation();
                        const updatedCarrierRelationshipContacts = [...values.CarrierRelationshipContacts];
                        updatedCarrierRelationshipContacts.splice(index, 1);
                        setFieldValue('CarrierRelationshipContacts', updatedCarrierRelationshipContacts);
                    }}
                >
                    <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2">
                        <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                    </svg>
                </Button>
            </CustomTooltip>
        )}

        {(carrierRelationshipContact?.RelationshipContactID && onEdit) && (
            <CustomTooltip TransitionComponent={Zoom} title="Delete">
                <span style={{ alignContent: "center" }}>
                    <Button
                        className="icon-btn"
                        color="inherit"
                        onClick={handleDeleteContactConfirm}
                    >
                        <svg width="21" height="24" viewBox="0 0 21 24" style={{ cursor: "pointer" }}>
                            <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                        </svg>
                    </Button>
                </span>
            </CustomTooltip>
        )}
    </div>
)}

        </div>
        :
        <></>
    )
}

export default RelationshipContactsTableRow
