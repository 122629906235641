import React from 'react';
import moment from 'moment';
import GenericFormikInput from '../../../../GenericFormikInput'
import cancelIconSVG from "../../../../../Icons/CancelIcon.svg";
import deleteForeverSVG from "../../../../../Icons/trash-xmark.svg";
import { CustomTooltip } from "../../../../Layout/Tooltip";
import { Button, Zoom } from "@material-ui/core";
import * as APIs from '../../../../../Data/APIs';
import { useModalDialog } from "../../../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../../../Modals/ConfirmModal";
import { SuccessModal } from "../../../../Modals/SuccessModal";
import { FailureModal } from "../../../../Modals/FailureModal";
import { useLoader } from "../../../../Layout/Loader";


const AgreementDocumentTableRow = ({ agreementDocument, legalDocumentTypes, index,currentMode = "editOrView", carrierDetails, addNewDocumentClicked, setAddNewDocumentClicked, getSingleCarrierHandler, onEdit, onSetEdit, formikObject, ...props }) => {
    const { touched, errors, values, setFieldValue, handleBlur, submitCount, resetForm } = formikObject || {}
    const [isEditable , setIsEditable]= React.useState(false);
    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [decodedString, setDecodedString] = React.useState("");

    React.useEffect(()=>{
        if (currentMode == 'add'){
            setIsEditable(true)
    }
    },[currentMode])

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }
    
    const handleDeleteAttachmentConfirm = async () => {
        openModal(0, async (e) => {
            setFieldValue(`LegalAttachments[${index}].isDelete`, 1);
        }, {
            title: 'Please Confirm',
            question: `Are you sure you want to delete this Agreement?`,
        });
    };

    const handleDownloadFile = async () => {
        try {
            openLoader();

            // Call the API to get the file data (base64 string)
            const response = await APIs.getCarrierSingleAttachment({
                CarrierID: carrierDetails?.CarrierID,
                GUID: agreementDocument?.GUID,
                attachmentExtension: agreementDocument?.AttachmentExtension,
            });
    
            // Assuming `response.data` contains the base64-encoded string of the file
            const decodedString = response?.data;
    
            if (!decodedString) {
                console.error("Failed to retrieve the file data.");
                return;
            }
    
            // Convert base64 to binary and trigger the download
            const binaryString = window.atob(decodedString); // Decode base64 string to binary
            const binaryLen = binaryString.length;
            const ab = new ArrayBuffer(binaryLen); // Create an ArrayBuffer
            const ia = new Uint8Array(ab); // Create a Uint8Array view of the ArrayBuffer
    
            // Populate the Uint8Array with the binary data
            for (let i = 0; i < binaryLen; i++) {
                ia[i] = binaryString.charCodeAt(i);
            }
    
            // Create a Blob from the ArrayBuffer
            const blob = new Blob([ab]);
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob); // Create a download URL from the Blob
    
            // Set the filename and trigger the download
            link.href = url;
            link.download = agreementDocument?.AttachmentName || "downloaded-file"; // Set the filename
            document.body.appendChild(link); // Append the link to the body (required for download in some browsers)
            link.click(); // Trigger the download
            document.body.removeChild(link); // Remove the link after download
            URL.revokeObjectURL(url); // Revoke the object URL to free up memory
    
        } catch (error) {
            console.error("Error downloading the file:", error);
        } finally {
            closeLoader();
        }
    };
    

    return (
        <>
            <div className="row table-row" id='agreement-documents'style={{ display: values?.LegalAttachments[index]?.isDelete === 1 ? 'none' : '' }}>
                <div className="col-3 table-cell first-cell d-flex flex-column word-break-all w-100">
                    
                        <GenericFormikInput
                            name={`LegalAttachments[${index}].CarrierLegalDocumentTypeId`}
                            type={'optionlist'}
                            optionsListConfig={{
                                optionsListMenu: legalDocumentTypes || [],
                                nameKey: 'CarrierLegalDocumentTypeName',
                                valueKey: 'CarrierLegalDocumentTypeId',
                            }}
                            isRequired={true}
                            isReadOnly={!onEdit}
                            readOnlyOptions={{
                                valueToShow: agreementDocument?.CarrierLegalDocumentTypeName,
                                extraCssClasses: "remove-textfield-div-style" 
                            }}
                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                        />
                    
                </div>
                <div className="col-3 table-cell w-100 d-flex flex-column" >
                    <GenericFormikInput
                        type="attachment"
                        name={`LegalAttachments[${index}].AttachmentName`}
                        formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                        isRequired={true}
                        isReadOnly={agreementDocument?.GUID}
                        readOnlyOptions={{
                            valueToShow: <span 
                            onClick={handleDownloadFile} 
                            style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                        >
                            {agreementDocument?.AttachmentName}
                        </span>,
                            extraCssClasses: "remove-textfield-div-style" 
                        }}
                        attachmentConfig={{
                            maxFileSize: 20 * 1024 * 1024,
                            dropzoneText: 'Attach File',
                            filesLimit: 1,
                            showPreviewsInDropzone: false,
                            onFileDelete: () => {
                            },
                            containerClass: 'containerClassDrag h-100',
                            extraCssClasses:'w-100'
                        }}
                    />
                </div>
                <div className="col-3 table-cell d-flex flex-column word-break-all">
                    
                        <GenericFormikInput
                            name={`LegalAttachments[${index}].LegalDocumentSignDate`}
                            type={'datetime'}
                            isRequired={true}
                            isReadOnly={!onEdit}
                            format="yyyy-MM-dd"
                            readOnlyOptions={{
                                valueToShow: agreementDocument?.LegalDocumentSignDate && moment(agreementDocument?.LegalDocumentSignDate).format('MM/DD/YYYY'),
                                extraCssClasses: "remove-textfield-div-style" 
                            }}
                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                        />
                    
                </div>
                <div className={`table-cell d-flex flex-column word-break-all ${onEdit ? 'col-2' : 'col-3'}`}>
                    
                        <GenericFormikInput
                            name={`LegalAttachments[${index}].LegalDocumentSignor`}
                            type={'text'}
                            isRequired={true}
                            isReadOnly={!onEdit}
                            readOnlyOptions={{
                                valueToShow: agreementDocument?.LegalDocumentSignor,
                                extraCssClasses: "remove-textfield-div-style" 
                            }}
                            formikObject={{ touched, errors, values, setFieldValue, handleBlur, submitCount }}
                        />
                    
                </div>
                {onEdit && (
                <div className="col-1 table-cell d-flex flex-column">
                <div>
                    {(!agreementDocument?.GUID) && (
                        <CustomTooltip TransitionComponent={Zoom} title="Cancel">
                        <Button 
                            className="pl-0 icon-btn" 
                            color="inherit" 
                            onClick={(e) => {
                                e.stopPropagation();
                                const updatedAgreementDocuments = [...values.LegalAttachments];
                                updatedAgreementDocuments.splice(index, 1);
                                setFieldValue('LegalAttachments', updatedAgreementDocuments);
                            }}
                        >
                            <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                                <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                            </svg>
                        </Button>
                    </CustomTooltip>
                    )}

                    {(agreementDocument?.GUID && onEdit)&& (
                        <CustomTooltip TransitionComponent={Zoom} title="Delete">
                            <span>
                                <Button 
                                    className="icon-btn" 
                                    color="inherit" 
                                    onClick={handleDeleteAttachmentConfirm}
                                >
                                    <svg width="21" height="24" viewBox="0 0 21 24" style={{ cursor: "pointer" }}>
                                        <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                    </svg>
                                </Button>
                            </span>
                        </CustomTooltip>
                    )}
                </div>

                </div>
                )}
            </div>
        </>
    )
}

export default AgreementDocumentTableRow
